import { all, put, takeLatest, select } from 'redux-saga/effects';
import { orderBy, find, keyBy, uniq, map, filter } from 'lodash';
import moment from 'moment';
import fetch from 'isomorphic-unfetch';
import jwtDecode from 'jwt-decode';
import {
    actionTypes,
    failure,
    loadUserDataSuccess,
    loadOrdersSuccess,
    loadOrderSuccess,
    loadOrdersCountSuccess,
    loadDoctorsSuccess,
    loadDoctorsCountSuccess,
    selectDoctorSuccess,
    loadLocaleSuccess,
    loadLocale,
    loadOrdersMessagesSuccess,
    organisationLoad,
    organisationLoadSuccess,
    searchDoctorsSuccess,
    onSaveMessageSuccess,
    onSaveInternalMessageSuccess,
    onNewCommentSuccess,
    onAllOrdersLoad,
    selectOrder,
    saveMessage,
    searchTechniciansSuccess,
    loadTechsSuccess
} from './actions';
import {
    selectOrderState,
    selectToken,
    selectProfile,
    selectMessageImage,
    selectInternalMessageImage
} from './selector';
import { GATEWAY_HOST } from '../utils/properties';
import { prepareOrderVersionsForDoctor } from '../utils/user';
import wsSagas from './sagas/wssaga';
import loadUsersSaga from './sagas/user';
import loadAllOrdersSaga from './sagas/orders';
import createNewOrderSaga from './sagas/order';
import { isEmpty } from '../utils/common';

function* selectDoctorSaga(action) {
    if (isEmpty(action.doctorId)) {
        return;
    }
    try {
        const token = yield select(selectToken);
        const res = yield fetch(`${GATEWAY_HOST}/users/${action.doctorId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        const data = yield res.json();
        yield put(selectDoctorSuccess(data));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* deleteOrderSaga(action) {
    if (isEmpty(action.orderId)) {
        return;
    }

    try {
        const token = yield select(selectToken);
        yield fetch(`${GATEWAY_HOST}/orders/${action.orderId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        yield put(onAllOrdersLoad());
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* selectOrderSaga(action) {
    if (isEmpty(action.orderId)) {
        return;
    }
    try {
        let token = yield select(selectToken);
        const profile = yield select(selectProfile);
        if (!isEmpty(token)) {
            yield fetch(`${GATEWAY_HOST}/viewers`, {
                method: 'POST',
                body: JSON.stringify({
                    user_id: profile.id,
                    order_id: action.orderId,
                    order: action.orderId,
                    when: moment.utc().format()
                }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
        } else {
            token = action.token;
        }
        const res = yield fetch(`${GATEWAY_HOST}/orders/${action.orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        // const { data } = res;
        let data = yield res.json();
        let orderversions = orderBy(
            data.orderversions,
            ['design_date'],
            ['desc']
        );

        let currentRole = profile.role ? profile.role.name : 'doctor';
        if (action.token) {
            currentRole = 'manager';
        }
        orderversions = prepareOrderVersionsForDoctor(
            orderversions,
            action.revisionId,
            currentRole
        );

        let version = orderversions[0];

        if (action.revisionId !== 'latest') {
            version = find(orderversions, {
                id: parseInt(action.revisionId, 10)
            });
            // delete version.id;
        }
        const imageIds = new Set();
        let i = 0;
        for (i = 0; i < orderversions.length; i += 1) {
            imageIds.add(orderversions[i].img_1);
            imageIds.add(orderversions[i].img_2);
            imageIds.add(orderversions[i].img_3);
            imageIds.add(orderversions[i].img_4);
            imageIds.add(orderversions[i].img_5);
            imageIds.add(orderversions[i].istl_1);
            imageIds.add(orderversions[i].istl_2);
            imageIds.add(orderversions[i].istl_3);
            imageIds.add(orderversions[i].istl_4);
            imageIds.add(orderversions[i].istl_5);
            imageIds.add(orderversions[i].istl_6);
            imageIds.add(orderversions[i].istl_7);
            imageIds.add(orderversions[i].istl_8);
            imageIds.add(orderversions[i].istl_9);
            imageIds.add(orderversions[i].istl_10);
            imageIds.add(orderversions[i].istl_11);
            imageIds.add(orderversions[i].istl_12);
            imageIds.add(orderversions[i].istl_13);
            imageIds.add(orderversions[i].istl_14);
            imageIds.add(orderversions[i].istl_15);
            imageIds.add(orderversions[i].istl_16);
            imageIds.add(orderversions[i].istl_17);
            imageIds.add(orderversions[i].istl_18);
            imageIds.add(orderversions[i].istl_19);
            imageIds.add(orderversions[i].istl_20);
            imageIds.add(orderversions[i].video);
            imageIds.add(orderversions[i].gif);
            imageIds.add(orderversions[i].gif2d);
            imageIds.add(orderversions[i].workorder);
            imageIds.add(orderversions[i].treatment);
            imageIds.add(orderversions[i].gif_splint);
            imageIds.add(orderversions[i].zip);
            // minivints
            imageIds.add(orderversions[i].minivints_image_1);
            imageIds.add(orderversions[i].minivints_image_2);
            imageIds.add(orderversions[i].minivints_image_3);
            imageIds.add(orderversions[i].minivints_image_4);
            imageIds.add(orderversions[i].minivints_image_5);
            imageIds.add(orderversions[i].minivints_image_6);
            imageIds.add(orderversions[i].minivints_image_7);
            imageIds.add(orderversions[i].minivints_image_8);
            imageIds.add(orderversions[i].minivints_image_9);
            imageIds.add(orderversions[i].minivints_image_10);
            // template
            imageIds.add(orderversions[i].template_image_1);
            imageIds.add(orderversions[i].template_image_2);
            imageIds.add(orderversions[i].template_image_3);
            imageIds.add(orderversions[i].template_image_4);
            imageIds.add(orderversions[i].template_image_5);
            imageIds.add(orderversions[i].template_image_6);
            imageIds.add(orderversions[i].template_image_7);
            imageIds.add(orderversions[i].template_image_8);
            imageIds.add(orderversions[i].template_image_9);
            imageIds.add(orderversions[i].template_image_10);
            // tool
            imageIds.add(orderversions[i].tool_image_1);
            imageIds.add(orderversions[i].tool_image_2);
            imageIds.add(orderversions[i].tool_image_3);
            imageIds.add(orderversions[i].tool_image_4);
            imageIds.add(orderversions[i].tool_image_5);
            imageIds.add(orderversions[i].tool_image_6);
            imageIds.add(orderversions[i].tool_image_7);
            imageIds.add(orderversions[i].tool_image_8);
            imageIds.add(orderversions[i].tool_image_9);
            imageIds.add(orderversions[i].tool_image_10);
            // tool 2d
            imageIds.add(orderversions[i].tool_2d_image_1);
            imageIds.add(orderversions[i].tool_2d_image_2);
            imageIds.add(orderversions[i].tool_2d_image_3);
            imageIds.add(orderversions[i].tool_2d_image_4);
            imageIds.add(orderversions[i].tool_2d_image_5);
            imageIds.add(orderversions[i].tool_2d_image_6);
            imageIds.add(orderversions[i].tool_2d_image_7);
            imageIds.add(orderversions[i].tool_2d_image_8);
            imageIds.add(orderversions[i].tool_2d_image_9);
            imageIds.add(orderversions[i].tool_2d_image_10);
            // virtual setup
            imageIds.add(orderversions[i].vs_image_1);
            imageIds.add(orderversions[i].vs_image_2);
            imageIds.add(orderversions[i].vs_image_3);
            imageIds.add(orderversions[i].vs_image_4);
            imageIds.add(orderversions[i].vs_image_5);
            imageIds.add(orderversions[i].vs_image_6);
            imageIds.add(orderversions[i].vs_image_7);
            imageIds.add(orderversions[i].vs_image_8);
            imageIds.add(orderversions[i].vs_image_9);
            imageIds.add(orderversions[i].vs_image_10);
            imageIds.add(orderversions[i].vs_image_11);
            imageIds.add(orderversions[i].vs_image_12);
            imageIds.add(orderversions[i].vs_image_13);
            imageIds.add(orderversions[i].vs_image_14);
            imageIds.add(orderversions[i].vs_image_15);
            // splint
            imageIds.add(orderversions[i].splint_image_1);
            imageIds.add(orderversions[i].splint_image_2);
            imageIds.add(orderversions[i].splint_image_3);
            imageIds.add(orderversions[i].splint_image_4);
            imageIds.add(orderversions[i].splint_image_5);
            imageIds.add(orderversions[i].splint_image_6);
            imageIds.add(orderversions[i].splint_image_7);
            imageIds.add(orderversions[i].splint_image_8);
            imageIds.add(orderversions[i].splint_image_9);
            imageIds.add(orderversions[i].splint_image_10);
        }
        imageIds.delete(undefined);
        imageIds.delete('');
        imageIds.delete(null);
        const queryStr = Array.from(imageIds)
            .map((id) => `id_in=${id}`)
            .join('&');

        const res2 = yield fetch(`${GATEWAY_HOST}/upload/files?${queryStr}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        const data2 = yield res2.json();
        const imgDict = keyBy(data2, 'id');

        for (i = 0; i < orderversions.length; i += 1) {
            orderversions[i].image_1 = imgDict[orderversions[i].img_1];
            orderversions[i].image_2 = imgDict[orderversions[i].img_2];
            orderversions[i].image_3 = imgDict[orderversions[i].img_3];
            orderversions[i].image_4 = imgDict[orderversions[i].img_4];
            orderversions[i].image_5 = imgDict[orderversions[i].img_5];
            orderversions[i].stl_1 = imgDict[orderversions[i].istl_1];
            orderversions[i].stl_2 = imgDict[orderversions[i].istl_2];
            orderversions[i].stl_3 = imgDict[orderversions[i].istl_3];
            orderversions[i].stl_4 = imgDict[orderversions[i].istl_4];
            orderversions[i].stl_5 = imgDict[orderversions[i].istl_5];
            orderversions[i].stl_6 = imgDict[orderversions[i].istl_6];
            orderversions[i].stl_7 = imgDict[orderversions[i].istl_7];
            orderversions[i].stl_8 = imgDict[orderversions[i].istl_8];
            orderversions[i].stl_9 = imgDict[orderversions[i].istl_9];
            orderversions[i].stl_10 = imgDict[orderversions[i].istl_10];
            orderversions[i].stl_11 = imgDict[orderversions[i].istl_11];
            orderversions[i].stl_12 = imgDict[orderversions[i].istl_12];
            orderversions[i].stl_13 = imgDict[orderversions[i].istl_13];
            orderversions[i].stl_14 = imgDict[orderversions[i].istl_14];
            orderversions[i].stl_15 = imgDict[orderversions[i].istl_15];
            orderversions[i].stl_16 = imgDict[orderversions[i].istl_16];
            orderversions[i].stl_17 = imgDict[orderversions[i].istl_17];
            orderversions[i].stl_18 = imgDict[orderversions[i].istl_18];
            orderversions[i].stl_19 = imgDict[orderversions[i].istl_19];
            orderversions[i].stl_20 = imgDict[orderversions[i].istl_20];
            orderversions[i].video = imgDict[orderversions[i].video];
            orderversions[i].gif = imgDict[orderversions[i].gif];
            orderversions[i].gif2d = imgDict[orderversions[i].gif2d];
            orderversions[i].workorder = imgDict[orderversions[i].workorder];
            orderversions[i].treatment = imgDict[orderversions[i].treatment];
            orderversions[i].gif_splint = imgDict[orderversions[i].gif_splint];
            orderversions[i].zip = imgDict[orderversions[i].zip];

            // minivints
            orderversions[i].minivints_image_1 =
                imgDict[orderversions[i].minivints_image_1];
            orderversions[i].minivints_image_2 =
                imgDict[orderversions[i].minivints_image_2];
            orderversions[i].minivints_image_3 =
                imgDict[orderversions[i].minivints_image_3];
            orderversions[i].minivints_image_4 =
                imgDict[orderversions[i].minivints_image_4];
            orderversions[i].minivints_image_5 =
                imgDict[orderversions[i].minivints_image_5];
            orderversions[i].minivints_image_6 =
                imgDict[orderversions[i].minivints_image_6];
            orderversions[i].minivints_image_7 =
                imgDict[orderversions[i].minivints_image_7];
            orderversions[i].minivints_image_8 =
                imgDict[orderversions[i].minivints_image_8];
            orderversions[i].minivints_image_9 =
                imgDict[orderversions[i].minivints_image_9];
            orderversions[i].minivints_image_10 =
                imgDict[orderversions[i].minivints_image_10];
            // template
            orderversions[i].template_image_1 =
                imgDict[orderversions[i].template_image_1];
            orderversions[i].template_image_2 =
                imgDict[orderversions[i].template_image_2];
            orderversions[i].template_image_3 =
                imgDict[orderversions[i].template_image_3];
            orderversions[i].template_image_4 =
                imgDict[orderversions[i].template_image_4];
            orderversions[i].template_image_5 =
                imgDict[orderversions[i].template_image_5];
            orderversions[i].template_image_6 =
                imgDict[orderversions[i].template_image_6];
            orderversions[i].template_image_7 =
                imgDict[orderversions[i].template_image_7];
            orderversions[i].template_image_8 =
                imgDict[orderversions[i].template_image_8];
            orderversions[i].template_image_9 =
                imgDict[orderversions[i].template_image_9];
            orderversions[i].template_image_10 =
                imgDict[orderversions[i].template_image_10];
            // tool
            orderversions[i].tool_image_1 =
                imgDict[orderversions[i].tool_image_1];
            orderversions[i].tool_image_2 =
                imgDict[orderversions[i].tool_image_2];
            orderversions[i].tool_image_3 =
                imgDict[orderversions[i].tool_image_3];
            orderversions[i].tool_image_4 =
                imgDict[orderversions[i].tool_image_4];
            orderversions[i].tool_image_5 =
                imgDict[orderversions[i].tool_image_5];
            orderversions[i].tool_image_6 =
                imgDict[orderversions[i].tool_image_6];
            orderversions[i].tool_image_7 =
                imgDict[orderversions[i].tool_image_7];
            orderversions[i].tool_image_8 =
                imgDict[orderversions[i].tool_image_8];
            orderversions[i].tool_image_9 =
                imgDict[orderversions[i].tool_image_9];
            orderversions[i].tool_image_10 =
                imgDict[orderversions[i].tool_image_10];
            // tool 2d
            orderversions[i].tool_2d_image_1 =
                imgDict[orderversions[i].tool_2d_image_1];
            orderversions[i].tool_2d_image_2 =
                imgDict[orderversions[i].tool_2d_image_2];
            orderversions[i].tool_2d_image_3 =
                imgDict[orderversions[i].tool_2d_image_3];
            orderversions[i].tool_2d_image_4 =
                imgDict[orderversions[i].tool_2d_image_4];
            orderversions[i].tool_2d_image_5 =
                imgDict[orderversions[i].tool_2d_image_5];
            orderversions[i].tool_2d_image_6 =
                imgDict[orderversions[i].tool_2d_image_6];
            orderversions[i].tool_2d_image_7 =
                imgDict[orderversions[i].tool_2d_image_7];
            orderversions[i].tool_2d_image_8 =
                imgDict[orderversions[i].tool_2d_image_8];
            orderversions[i].tool_2d_image_9 =
                imgDict[orderversions[i].tool_2d_image_9];
            orderversions[i].tool_2d_image_10 =
                imgDict[orderversions[i].tool_2d_image_10];
            // virtual setup
            orderversions[i].vs_image_1 = imgDict[orderversions[i].vs_image_1];
            orderversions[i].vs_image_2 = imgDict[orderversions[i].vs_image_2];
            orderversions[i].vs_image_3 = imgDict[orderversions[i].vs_image_3];
            orderversions[i].vs_image_4 = imgDict[orderversions[i].vs_image_4];
            orderversions[i].vs_image_5 = imgDict[orderversions[i].vs_image_5];
            orderversions[i].vs_image_6 = imgDict[orderversions[i].vs_image_6];
            orderversions[i].vs_image_7 = imgDict[orderversions[i].vs_image_7];
            orderversions[i].vs_image_8 = imgDict[orderversions[i].vs_image_8];
            orderversions[i].vs_image_9 = imgDict[orderversions[i].vs_image_9];
            orderversions[i].vs_image_10 =
                imgDict[orderversions[i].vs_image_10];
            orderversions[i].vs_image_11 =
                imgDict[orderversions[i].vs_image_11];
            orderversions[i].vs_image_12 =
                imgDict[orderversions[i].vs_image_12];
            orderversions[i].vs_image_13 =
                imgDict[orderversions[i].vs_image_13];
            orderversions[i].vs_image_14 =
                imgDict[orderversions[i].vs_image_14];
            orderversions[i].vs_image_15 =
                imgDict[orderversions[i].vs_image_15];
            // splint
            orderversions[i].splint_image_1 =
                imgDict[orderversions[i].splint_image_1];
            orderversions[i].splint_image_2 =
                imgDict[orderversions[i].splint_image_2];
            orderversions[i].splint_image_3 =
                imgDict[orderversions[i].splint_image_3];
            orderversions[i].splint_image_4 =
                imgDict[orderversions[i].splint_image_4];
            orderversions[i].splint_image_5 =
                imgDict[orderversions[i].splint_image_5];
            orderversions[i].splint_image_6 =
                imgDict[orderversions[i].splint_image_6];
            orderversions[i].splint_image_7 =
                imgDict[orderversions[i].splint_image_7];
            orderversions[i].splint_image_8 =
                imgDict[orderversions[i].splint_image_8];
            orderversions[i].splint_image_9 =
                imgDict[orderversions[i].splint_image_9];
            orderversions[i].splint_image_10 =
                imgDict[orderversions[i].splint_image_10];
        }

        for (i = 0; i < orderversions.length; i += 1) {
            const orderVersionWithSlices = orderversions[i];
            if (orderVersionWithSlices.zip) {
                const slicesRequest = yield fetch(
                    `${GATEWAY_HOST}/slices?zip=${orderVersionWithSlices.zip.hash}`,
                    {
                        headers: {
                            // Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                const slices = yield slicesRequest.json();
                orderversions[i].slices =
                    slices.length > 0 ? slices[0].data : null;
            }
        }

        data = {
            ...data,
            // status: version.status,
            backup: version.backup,
            status_minivints: version.status_minivints,
            status_equipment: version.status_equipment,
            status_equipment_2d: version.status_equipment_2d,
            status_braces: version.status_braces,
            status_aligners: version.status_aligners,
            status_template: version.status_template,
            status_tray: version.status_tray,
            status_splint: version.status_splint,
            version: version.version,
            design_date: version.design_date,
            image_1: version.image_1,
            image_2: version.image_2,
            image_3: version.image_3,
            image_4: version.image_4,
            image_5: version.image_5,
            // minivints
            minivints_image_1: version.minivints_image_1,
            minivints_image_2: version.minivints_image_2,
            minivints_image_3: version.minivints_image_3,
            minivints_image_4: version.minivints_image_4,
            minivints_image_5: version.minivints_image_5,
            minivints_image_6: version.minivints_image_6,
            minivints_image_7: version.minivints_image_7,
            minivints_image_8: version.minivints_image_8,
            minivints_image_9: version.minivints_image_9,
            minivints_image_10: version.minivints_image_10,
            minivints_label_1: version.minivints_label_1,
            minivints_label_2: version.minivints_label_2,
            minivints_label_3: version.minivints_label_3,
            minivints_label_4: version.minivints_label_4,
            minivints_label_5: version.minivints_label_5,
            minivints_label_6: version.minivints_label_6,
            minivints_label_7: version.minivints_label_7,
            minivints_label_8: version.minivints_label_8,
            minivints_label_9: version.minivints_label_9,
            minivints_label_10: version.minivints_label_10,
            // template
            template_image_1: version.template_image_1,
            template_image_2: version.template_image_2,
            template_image_3: version.template_image_3,
            template_image_4: version.template_image_4,
            template_image_5: version.template_image_5,
            template_image_6: version.template_image_6,
            template_image_7: version.template_image_7,
            template_image_8: version.template_image_8,
            template_image_9: version.template_image_9,
            template_image_10: version.template_image_10,
            template_label_1: version.template_label_1,
            template_label_2: version.template_label_2,
            template_label_3: version.template_label_3,
            template_label_4: version.template_label_4,
            template_label_5: version.template_label_5,
            template_label_6: version.template_label_6,
            template_label_7: version.template_label_7,
            template_label_8: version.template_label_8,
            template_label_9: version.template_label_9,
            template_label_10: version.template_label_10,
            // tool
            tool_image_1: version.tool_image_1,
            tool_image_2: version.tool_image_2,
            tool_image_3: version.tool_image_3,
            tool_image_4: version.tool_image_4,
            tool_image_5: version.tool_image_5,
            tool_image_6: version.tool_image_6,
            tool_image_7: version.tool_image_7,
            tool_image_8: version.tool_image_8,
            tool_image_9: version.tool_image_9,
            tool_image_10: version.tool_image_10,
            tool_label_1: version.tool_label_1,
            tool_label_2: version.tool_label_2,
            tool_label_3: version.tool_label_3,
            tool_label_4: version.tool_label_4,
            tool_label_5: version.tool_label_5,
            tool_label_6: version.tool_label_6,
            tool_label_7: version.tool_label_7,
            tool_label_8: version.tool_label_8,
            tool_label_9: version.tool_label_9,
            tool_label_10: version.tool_label_10,
            // tool 2d
            tool_2d_image_1: version.tool_2d_image_1,
            tool_2d_image_2: version.tool_2d_image_2,
            tool_2d_image_3: version.tool_2d_image_3,
            tool_2d_image_4: version.tool_2d_image_4,
            tool_2d_image_5: version.tool_2d_image_5,
            tool_2d_image_6: version.tool_2d_image_6,
            tool_2d_image_7: version.tool_2d_image_7,
            tool_2d_image_8: version.tool_2d_image_8,
            tool_2d_image_9: version.tool_2d_image_9,
            tool_2d_image_10: version.tool_2d_image_10,
            tool_2d_label_1: version.tool_2d_label_1,
            tool_2d_label_2: version.tool_2d_label_2,
            tool_2d_label_3: version.tool_2d_label_3,
            tool_2d_label_4: version.tool_2d_label_4,
            tool_2d_label_5: version.tool_2d_label_5,
            tool_2d_label_6: version.tool_2d_label_6,
            tool_2d_label_7: version.tool_2d_label_7,
            tool_2d_label_8: version.tool_2d_label_8,
            tool_2d_label_9: version.tool_2d_label_9,
            tool_2d_label_10: version.tool_2d_label_10,
            // virtual setup
            vs_image_1: version.vs_image_1,
            vs_image_2: version.vs_image_2,
            vs_image_3: version.vs_image_3,
            vs_image_4: version.vs_image_4,
            vs_image_5: version.vs_image_5,
            vs_image_6: version.vs_image_6,
            vs_image_7: version.vs_image_7,
            vs_image_8: version.vs_image_8,
            vs_image_9: version.vs_image_9,
            vs_image_10: version.vs_image_10,
            vs_image_11: version.vs_image_11,
            vs_image_12: version.vs_image_12,
            vs_image_13: version.vs_image_13,
            vs_image_14: version.vs_image_14,
            vs_image_15: version.vs_image_15,
            vs_label_1: version.vs_label_1,
            vs_label_2: version.vs_label_2,
            vs_label_3: version.vs_label_3,
            vs_label_4: version.vs_label_4,
            vs_label_5: version.vs_label_5,
            vs_label_6: version.vs_label_6,
            vs_label_7: version.vs_label_7,
            vs_label_8: version.vs_label_8,
            vs_label_9: version.vs_label_9,
            vs_label_10: version.vs_label_10,
            vs_label_11: version.vs_label_11,
            vs_label_12: version.vs_label_12,
            vs_label_13: version.vs_label_13,
            vs_label_14: version.vs_label_14,
            vs_label_15: version.vs_label_15,
            // splint
            splint_image_1: version.splint_image_1,
            splint_image_2: version.splint_image_2,
            splint_image_3: version.splint_image_3,
            splint_image_4: version.splint_image_4,
            splint_image_5: version.splint_image_5,
            splint_image_6: version.splint_image_6,
            splint_image_7: version.splint_image_7,
            splint_image_8: version.splint_image_8,
            splint_image_9: version.splint_image_9,
            splint_image_10: version.splint_image_10,
            splint_label_1: version.splint_label_1,
            splint_label_2: version.splint_label_2,
            splint_label_3: version.splint_label_3,
            splint_label_4: version.splint_label_4,
            splint_label_5: version.splint_label_5,
            splint_label_6: version.splint_label_6,
            splint_label_7: version.splint_label_7,
            splint_label_8: version.splint_label_8,
            splint_label_9: version.splint_label_9,
            splint_label_10: version.splint_label_10,
            // labels
            label_1: version.label_1,
            label_2: version.label_2,
            label_3: version.label_3,
            label_4: version.label_4,
            label_5: version.label_5,
            stl_1: version.stl_1,
            stl_2: version.stl_2,
            stl_3: version.stl_3,
            stl_4: version.stl_4,
            stl_5: version.stl_5,
            stl_6: version.stl_6,
            stl_7: version.stl_7,
            stl_8: version.stl_8,
            stl_9: version.stl_9,
            stl_10: version.stl_10,
            stl_11: version.stl_11,
            stl_12: version.stl_12,
            stl_13: version.stl_13,
            stl_14: version.stl_14,
            stl_15: version.stl_15,
            stl_16: version.stl_16,
            stl_17: version.stl_17,
            stl_18: version.stl_18,
            stl_19: version.stl_19,
            stl_20: version.stl_20,
            video: version.video,
            gif: version.gif,
            gif2d: version.gif2d,
            workorder: version.workorder,
            treatment: version.treatment,
            gif_splint: version.gif_splint,
            zip: version.zip,
            isPublic: version.public
        };
        const res3 = yield fetch(`${GATEWAY_HOST}/users/${data.doctor.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        const data3 = yield res3.json();
        data = {
            ...data,
            doctor: {
                ...data.doctor,
                organisations: data3.organisations
            }
        };
        data.orderversions = orderversions;

        yield put(loadOrderSuccess(data));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* selectOrderBaseSaga(action) {
    if (isEmpty(action.orderId)) {
        return;
    }
    const token = yield select(selectToken);
    try {
        const res = yield fetch(`${GATEWAY_HOST}/orders/${action.orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        let data = yield res.json();
        const res3 = yield fetch(`${GATEWAY_HOST}/users/${data.doctor.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        const data3 = yield res3.json();
        data = {
            ...data,
            doctor: {
                ...data.doctor,
                organisations: data3.organisations
            }
        };
        yield put(loadOrderSuccess(data));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* onSaveStlSettingsSaga(action) {
    try {
        const token = yield select(selectToken);
        const profile = yield select(selectProfile);
        let method = 'POST';
        let url = `${GATEWAY_HOST}/stls`;
        const body = {
            user: profile.id,
            model_color: action.newModelColor,
            model_after_color: action.newModelAfterColor,
            minivints_color: action.newMinivintsColor,
            template_color: action.newTemplateColor,
            tool_color: action.newToolColor,
            tray_color: action.newTrayColor,
            model_shineness: action.newModelShineness,
            model_after_shineness: action.newModelAfterShineness,
            minivints_shineness: action.newMinivintsShineness,
            template_shineness: action.newTemplateShineness,
            tool_shineness: action.newToolShineness,
            tray_shineness: action.newTrayShineness,
            tray_opacity: action.newTrayOpacity,
            splint_color: action.newSplintColor,
            splint_shineness: action.newSplintShineness,
            splint_opacity: action.newSplintOpacity,
            maxillary_color: action.newMaxillaryColor,
            maxillary_shineness: action.newMaxillaryShineness
        };
        if (profile.stl) {
            method = 'PUT';
            url = `${url}/${profile.stl.id}`;
        }

        yield fetch(url, {
            method,
            body: JSON.stringify(body),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* saveMessageSaga(action) {
    try {
        const token = yield select(selectToken);
        const decodedToken = jwtDecode(token);
        const authorId = decodedToken.id;
        const profile = yield select(selectProfile);
        const imageRaw = yield select(selectMessageImage);

        // const author = `${profile.lastName} ${profile.firstName} ${profile.middleName}`;
        let author = '';
        if (!isEmpty(profile.lastName)) {
            author += profile.lastName;
        }

        if (!isEmpty(profile.firstName)) {
            author += ` ${profile.firstName}`;
        }

        if (!isEmpty(profile.middleName)) {
            author += ` ${profile.middleName}`;
        }

        const role = profile.role.name;
        const body = {
            author,
            comment: action.message,
            date: action.date,
            order: action.orderId,
            order_id: action.orderId,
            recepient_id: `${action.doctorId}`,
            author_id: `${authorId}`,
            role
        };

        try {
            const image =
                imageRaw[0].response[0].hash + imageRaw[0].response[0].ext;
            body.image = image;
        } catch (e) {
            console.debug(e);
        }

        yield fetch(`${GATEWAY_HOST}/messages`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        yield fetch(`${GATEWAY_HOST}/viewers`, {
            method: 'POST',
            body: JSON.stringify({
                user_id: profile.id,
                order_id: action.orderId,
                order: action.orderId,
                when: moment.utc().format()
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        yield fetch(`${GATEWAY_HOST}/orders/${action.orderId}`, {
            method: 'PUT',
            body: JSON.stringify({
                latest_message: action.date
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        yield put(onSaveMessageSuccess());
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* saveInternalMessageSaga(action) {
    try {
        const token = yield select(selectToken);
        const decodedToken = jwtDecode(token);
        const authorId = decodedToken.id;
        const profile = yield select(selectProfile);
        const imageRaw = yield select(selectInternalMessageImage);

        // const author = `${profile.lastName} ${profile.firstName} ${profile.middleName}`;
        let author = '';
        if (!isEmpty(profile.lastName)) {
            author += profile.lastName;
        }

        if (!isEmpty(profile.firstName)) {
            author += ` ${profile.firstName}`;
        }

        if (!isEmpty(profile.middleName)) {
            author += ` ${profile.middleName}`;
        }

        const role = profile.role.name;
        const body = {
            author,
            comment: action.message,
            date: action.date,
            order: action.orderId,
            order_id: `${action.orderId}`,
            recepient_id: `${action.doctorId}`,
            author_id: `${authorId}`,
            role
        };

        try {
            const image =
                imageRaw[0].response[0].hash + imageRaw[0].response[0].ext;
            body.image = image;
        } catch (e) {
            console.debug(e);
        }

        yield fetch(`${GATEWAY_HOST}/comments`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        yield put(onSaveInternalMessageSuccess());
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* selectMessageOrderSaga(action) {
    try {
        const token = yield select(selectToken);
        const decodedToken = jwtDecode(token);
        const profile = yield select(selectProfile);

        yield fetch(`${GATEWAY_HOST}/viewers`, {
            method: 'POST',
            body: JSON.stringify({
                user_id: profile.id,
                order_id: action.order.id,
                order: action.order.id,
                when: moment.utc().format()
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        const doctorFilter =
            profile.role.name === 'doctor'
                ? `?doctor_eq=${decodedToken.id}&_limit=1000`
                : '?_limit=1000';
        const res = yield fetch(`${GATEWAY_HOST}/orders${doctorFilter}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const unreadRes = yield fetch(
            `${GATEWAY_HOST}/orders/updated?user_id=${decodedToken.id}&role=${profile.role.name}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const unread = yield unreadRes.json();
        const data = yield res.json();
        yield put(loadOrdersMessagesSuccess(data, unread));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* saveOrderDataSaga(action) {
    try {
        const token = yield select(selectToken);
        const orderState = yield select(selectOrderState);
        const { selectedOrder } = orderState;
        const { orderId } = action.data;
        const body = {
            doctor: action.data.doctor,
            technician: action.data.technician,
            manager: action.data.manager,
            patient: action.data.patient,
            bill: action.data.bill,
            bill_date: action.data.bill_date,
            deadline: action.data.deadline,
            search: `${action.data.doctor_last_name}&${action.data.patient}&${action.data.bill}`,
            status: action.data.status,
            organisation: action.data.organisation,
            status_minivints: action.data.status_minivints,
            status_template: action.data.status_template,
            status_equipment: action.data.status_equipment,
            status_equipment_2d: action.data.status_equipment_2d,
            status_braces: action.data.status_braces,
            status_aligners: action.data.status_aligners,
            status_tray: action.data.status_tray,
            status_splint: action.data.status_splint,
            latest: action.data.design_date,
            version: action.data.version
        };

        const body2 = {
            order: orderId,
            design_date: action.data.design_date,
            // image_1: action.data.image1,
            // image_2: action.data.image2,
            // image_3: action.data.image3,
            // image_4: action.data.image4,
            // image_5: action.data.image5,
            img_1: action.data.image1,
            img_2: action.data.image2,
            img_3: action.data.image3,
            img_4: action.data.image4,
            img_5: action.data.image5,
            // minivints
            minivints_image_1: action.data.minivintsImage1,
            minivints_image_2: action.data.minivintsImage2,
            minivints_image_3: action.data.minivintsImage3,
            minivints_image_4: action.data.minivintsImage4,
            minivints_image_5: action.data.minivintsImage5,
            minivints_image_6: action.data.minivintsImage6,
            minivints_image_7: action.data.minivintsImage7,
            minivints_image_8: action.data.minivintsImage8,
            minivints_image_9: action.data.minivintsImage9,
            minivints_image_10: action.data.minivintsImage10,
            minivints_label_1: action.data.minivintsLabel1,
            minivints_label_2: action.data.minivintsLabel2,
            minivints_label_3: action.data.minivintsLabel3,
            minivints_label_4: action.data.minivintsLabel4,
            minivints_label_5: action.data.minivintsLabel5,
            minivints_label_6: action.data.minivintsLabel6,
            minivints_label_7: action.data.minivintsLabel7,
            minivints_label_8: action.data.minivintsLabel8,
            minivints_label_9: action.data.minivintsLabel9,
            minivints_label_10: action.data.minivintsLabel10,
            // template
            template_image_1: action.data.templateImage1,
            template_image_2: action.data.templateImage2,
            template_image_3: action.data.templateImage3,
            template_image_4: action.data.templateImage4,
            template_image_5: action.data.templateImage5,
            template_image_6: action.data.templateImage6,
            template_image_7: action.data.templateImage7,
            template_image_8: action.data.templateImage8,
            template_image_9: action.data.templateImage9,
            template_image_10: action.data.templateImage10,
            template_label_1: action.data.templateLabel1,
            template_label_2: action.data.templateLabel2,
            template_label_3: action.data.templateLabel3,
            template_label_4: action.data.templateLabel4,
            template_label_5: action.data.templateLabel5,
            template_label_6: action.data.templateLabel6,
            template_label_7: action.data.templateLabel7,
            template_label_8: action.data.templateLabel8,
            template_label_9: action.data.templateLabel9,
            template_label_10: action.data.templateLabel10,
            // tool
            tool_image_1: action.data.toolImage1,
            tool_image_2: action.data.toolImage2,
            tool_image_3: action.data.toolImage3,
            tool_image_4: action.data.toolImage4,
            tool_image_5: action.data.toolImage5,
            tool_image_6: action.data.toolImage6,
            tool_image_7: action.data.toolImage7,
            tool_image_8: action.data.toolImage8,
            tool_image_9: action.data.toolImage9,
            tool_image_10: action.data.toolImage10,
            tool_label_1: action.data.toolLabel1,
            tool_label_2: action.data.toolLabel2,
            tool_label_3: action.data.toolLabel3,
            tool_label_4: action.data.toolLabel4,
            tool_label_5: action.data.toolLabel5,
            tool_label_6: action.data.toolLabel6,
            tool_label_7: action.data.toolLabel7,
            tool_label_8: action.data.toolLabel8,
            tool_label_9: action.data.toolLabel9,
            tool_label_10: action.data.toolLabel10,
            // tool 2d
            tool_2d_image_1: action.data.tool2dImage1,
            tool_2d_image_2: action.data.tool2dImage2,
            tool_2d_image_3: action.data.tool2dImage3,
            tool_2d_image_4: action.data.tool2dImage4,
            tool_2d_image_5: action.data.tool2dImage5,
            tool_2d_image_6: action.data.tool2dImage6,
            tool_2d_image_7: action.data.tool2dImage7,
            tool_2d_image_8: action.data.tool2dImage8,
            tool_2d_image_9: action.data.tool2dImage9,
            tool_2d_image_10: action.data.tool2dImage10,
            tool_2d_label_1: action.data.tool2dLabel1,
            tool_2d_label_2: action.data.tool2dLabel2,
            tool_2d_label_3: action.data.tool2dLabel3,
            tool_2d_label_4: action.data.tool2dLabel4,
            tool_2d_label_5: action.data.tool2dLabel5,
            tool_2d_label_6: action.data.tool2dLabel6,
            tool_2d_label_7: action.data.tool2dLabel7,
            tool_2d_label_8: action.data.tool2dLabel8,
            tool_2d_label_9: action.data.tool2dLabel9,
            tool_2d_label_10: action.data.tool2dLabel10,
            // virtual setup
            vs_image_1: action.data.vsImage1,
            vs_image_2: action.data.vsImage2,
            vs_image_3: action.data.vsImage3,
            vs_image_4: action.data.vsImage4,
            vs_image_5: action.data.vsImage5,
            vs_image_6: action.data.vsImage6,
            vs_image_7: action.data.vsImage7,
            vs_image_8: action.data.vsImage8,
            vs_image_9: action.data.vsImage9,
            vs_image_10: action.data.vsImage10,
            vs_image_11: action.data.vsImage11,
            vs_image_12: action.data.vsImage12,
            vs_image_13: action.data.vsImage13,
            vs_image_14: action.data.vsImage14,
            vs_image_15: action.data.vsImage15,
            vs_label_1: action.data.vsLabel1,
            vs_label_2: action.data.vsLabel2,
            vs_label_3: action.data.vsLabel3,
            vs_label_4: action.data.vsLabel4,
            vs_label_5: action.data.vsLabel5,
            vs_label_6: action.data.vsLabel6,
            vs_label_7: action.data.vsLabel7,
            vs_label_8: action.data.vsLabel8,
            vs_label_9: action.data.vsLabel9,
            vs_label_10: action.data.vsLabel10,
            vs_label_11: action.data.vsLabel11,
            vs_label_12: action.data.vsLabel12,
            vs_label_13: action.data.vsLabel13,
            vs_label_14: action.data.vsLabel14,
            vs_label_15: action.data.vsLabel15,
            // splint
            splint_image_1: action.data.splintImage1,
            splint_image_2: action.data.splintImage2,
            splint_image_3: action.data.splintImage3,
            splint_image_4: action.data.splintImage4,
            splint_image_5: action.data.splintImage5,
            splint_image_6: action.data.splintImage6,
            splint_image_7: action.data.splintImage7,
            splint_image_8: action.data.splintImage8,
            splint_image_9: action.data.splintImage9,
            splint_image_10: action.data.splintImage10,
            splint_label_1: action.data.splintLabel1,
            splint_label_2: action.data.splintLabel2,
            splint_label_3: action.data.splintLabel3,
            splint_label_4: action.data.splintLabel4,
            splint_label_5: action.data.splintLabel5,
            splint_label_6: action.data.splintLabel6,
            splint_label_7: action.data.splintLabel7,
            splint_label_8: action.data.splintLabel8,
            splint_label_9: action.data.splintLabel9,
            splint_label_10: action.data.splintLabel10,
            // labels
            label_1: action.data.label1,
            label_2: action.data.label2,
            label_3: action.data.label3,
            label_4: action.data.label4,
            label_5: action.data.label5,
            // stl_1: action.data.stl1,
            // stl_2: action.data.stl2,
            // stl_3: action.data.stl3,
            // stl_4: action.data.stl4,
            istl_1: action.data.stl1,
            istl_2: action.data.stl2,
            istl_3: action.data.stl3,
            istl_4: action.data.stl4,
            istl_5: action.data.stl5,
            istl_6: action.data.stl6,
            istl_7: action.data.stl7,
            istl_8: action.data.stl8,
            istl_9: action.data.stl9,
            istl_10: action.data.stl10,
            istl_11: action.data.stl11,
            istl_12: action.data.stl12,
            istl_13: action.data.stl13,
            istl_14: action.data.stl14,
            istl_15: action.data.stl15,
            istl_16: action.data.stl16,
            istl_17: action.data.stl17,
            istl_18: action.data.stl18,
            istl_19: action.data.stl19,
            istl_20: action.data.stl20,
            video: action.data.video,
            gif: action.data.gif,
            gif2d: action.data.gif2d,
            workorder: action.data.workorder,
            treatment: action.data.treatment,
            gif_splint: action.data.gif_splint,
            zip: action.data.zip,
            status: action.data.status,
            status_minivints: action.data.status_minivints,
            status_template: action.data.status_template,
            status_equipment: action.data.status_equipment,
            status_equipment_2d: action.data.status_equipment_2d,
            status_braces: action.data.status_braces,
            status_aligners: action.data.status_aligners,
            status_tray: action.data.status_tray,
            status_splint: action.data.status_splint,
            public: action.data.public,
            version: action.data.version
        };

        const orderedVersions = orderBy(
            selectedOrder.orderversions,
            ['design_date'],
            ['desc']
        );

        const versionsRaw = uniq(map(orderedVersions, 'version'));

        for (let i = 0; i < versionsRaw.length; i += 1) {
            const currentVersion = versionsRaw[i];
            if (currentVersion !== action.data.version) {
                const ovs = filter(
                    orderedVersions,
                    (item) => item.version === currentVersion
                );
                const ov = ovs[0];
                if (action.data.status !== ov.status) {
                    const body3 = {
                        order: ov.order,
                        design_date: ov.design_date,
                        img_1: ov.img_1,
                        img_2: ov.img_2,
                        img_3: ov.img_3,
                        img_4: ov.img_4,
                        img_5: ov.img_5,
                        // minivints
                        minivints_image_1: ov.minivints_image_1,
                        minivints_image_2: ov.minivints_image_2,
                        minivints_image_3: ov.minivints_image_3,
                        minivints_image_4: ov.minivints_image_4,
                        minivints_image_5: ov.minivints_image_5,
                        minivints_image_6: ov.minivints_image_6,
                        minivints_image_7: ov.minivints_image_7,
                        minivints_image_8: ov.minivints_image_8,
                        minivints_image_9: ov.minivints_image_9,
                        minivints_image_10: ov.minivints_image_10,
                        minivints_label_1: ov.minivints_label_1,
                        minivints_label_2: ov.minivints_label_2,
                        minivints_label_3: ov.minivints_label_3,
                        minivints_label_4: ov.minivints_label_4,
                        minivints_label_5: ov.minivints_label_5,
                        minivints_label_6: ov.minivints_label_6,
                        minivints_label_7: ov.minivints_label_7,
                        minivints_label_8: ov.minivints_label_8,
                        minivints_label_9: ov.minivints_label_9,
                        minivints_label_10: ov.minivints_label_10,
                        // template
                        template_image_1: ov.template_image_1,
                        template_image_2: ov.template_image_2,
                        template_image_3: ov.template_image_3,
                        template_image_4: ov.template_image_4,
                        template_image_5: ov.template_image_5,
                        template_image_6: ov.template_image_6,
                        template_image_7: ov.template_image_7,
                        template_image_8: ov.template_image_8,
                        template_image_9: ov.template_image_9,
                        template_image_10: ov.template_image_10,
                        template_label_1: ov.template_label_1,
                        template_label_2: ov.template_label_2,
                        template_label_3: ov.template_label_3,
                        template_label_4: ov.template_label_4,
                        template_label_5: ov.template_label_5,
                        template_label_6: ov.template_label_6,
                        template_label_7: ov.template_label_7,
                        template_label_8: ov.template_label_8,
                        template_label_9: ov.template_label_9,
                        template_label_10: ov.template_label_10,
                        // tool
                        tool_image_1: ov.tool_image_1,
                        tool_image_2: ov.tool_image_2,
                        tool_image_3: ov.tool_image_3,
                        tool_image_4: ov.tool_image_4,
                        tool_image_5: ov.tool_image_5,
                        tool_image_6: ov.tool_image_6,
                        tool_image_7: ov.tool_image_7,
                        tool_image_8: ov.tool_image_8,
                        tool_image_9: ov.tool_image_9,
                        tool_image_10: ov.tool_image_10,
                        tool_label_1: ov.tool_label_1,
                        tool_label_2: ov.tool_label_2,
                        tool_label_3: ov.tool_label_3,
                        tool_label_4: ov.tool_label_4,
                        tool_label_5: ov.tool_label_5,
                        tool_label_6: ov.tool_label_6,
                        tool_label_7: ov.tool_label_7,
                        tool_label_8: ov.tool_label_8,
                        tool_label_9: ov.tool_label_9,
                        tool_label_10: ov.tool_label_10,
                        // tool 2d
                        tool_2d_image_1: ov.tool_2d_image_1,
                        tool_2d_image_2: ov.tool_2d_image_2,
                        tool_2d_image_3: ov.tool_2d_image_3,
                        tool_2d_image_4: ov.tool_2d_image_4,
                        tool_2d_image_5: ov.tool_2d_image_5,
                        tool_2d_image_6: ov.tool_2d_image_6,
                        tool_2d_image_7: ov.tool_2d_image_7,
                        tool_2d_image_8: ov.tool_2d_image_8,
                        tool_2d_image_9: ov.tool_2d_image_9,
                        tool_2d_image_10: ov.tool_2d_image_10,
                        tool_2d_label_1: ov.tool_2d_label_1,
                        tool_2d_label_2: ov.tool_2d_label_2,
                        tool_2d_label_3: ov.tool_2d_label_3,
                        tool_2d_label_4: ov.tool_2d_label_4,
                        tool_2d_label_5: ov.tool_2d_label_5,
                        tool_2d_label_6: ov.tool_2d_label_6,
                        tool_2d_label_7: ov.tool_2d_label_7,
                        tool_2d_label_8: ov.tool_2d_label_8,
                        tool_2d_label_9: ov.tool_2d_label_9,
                        tool_2d_label_10: ov.tool_2d_label_10,
                        // virtual setup
                        vs_image_1: ov.vs_image_1,
                        vs_image_2: ov.vs_image_2,
                        vs_image_3: ov.vs_image_3,
                        vs_image_4: ov.vs_image_4,
                        vs_image_5: ov.vs_image_5,
                        vs_image_6: ov.vs_image_6,
                        vs_image_7: ov.vs_image_7,
                        vs_image_8: ov.vs_image_8,
                        vs_image_9: ov.vs_image_9,
                        vs_image_10: ov.vs_image_10,
                        vs_image_11: ov.vs_image_11,
                        vs_image_12: ov.vs_image_12,
                        vs_image_13: ov.vs_image_13,
                        vs_image_14: ov.vs_image_14,
                        vs_image_15: ov.vs_image_15,
                        vs_label_1: ov.vs_label_1,
                        vs_label_2: ov.vs_label_2,
                        vs_label_3: ov.vs_label_3,
                        vs_label_4: ov.vs_label_4,
                        vs_label_5: ov.vs_label_5,
                        vs_label_6: ov.vs_label_6,
                        vs_label_7: ov.vs_label_7,
                        vs_label_8: ov.vs_label_8,
                        vs_label_9: ov.vs_label_9,
                        vs_label_10: ov.vs_label_10,
                        vs_label_11: ov.vs_label_11,
                        vs_label_12: ov.vs_label_12,
                        vs_label_13: ov.vs_label_13,
                        vs_label_14: ov.vs_label_14,
                        vs_label_15: ov.vs_label_15,
                        // splint
                        splint_image_1: ov.splint_image_1,
                        splint_image_2: ov.splint_image_2,
                        splint_image_3: ov.splint_image_3,
                        splint_image_4: ov.splint_image_4,
                        splint_image_5: ov.splint_image_5,
                        splint_image_6: ov.splint_image_6,
                        splint_image_7: ov.splint_image_7,
                        splint_image_8: ov.splint_image_8,
                        splint_image_9: ov.splint_image_9,
                        splint_image_10: ov.splint_image_10,
                        splint_label_1: ov.splint_label_1,
                        splint_label_2: ov.splint_label_2,
                        splint_label_3: ov.splint_label_3,
                        splint_label_4: ov.splint_label_4,
                        splint_label_5: ov.splint_label_5,
                        splint_label_6: ov.splint_label_6,
                        splint_label_7: ov.splint_label_7,
                        splint_label_8: ov.splint_label_8,
                        splint_label_9: ov.splint_label_9,
                        splint_label_10: ov.splint_label_10,
                        // labels
                        label_1: ov.label_1,
                        label_2: ov.label_2,
                        label_3: ov.label_3,
                        label_4: ov.label_4,
                        label_5: ov.label_5,
                        // stl_1: ov.stl1,
                        // stl_2: ov.stl2,
                        // stl_3: ov.stl3,
                        // stl_4: ov.stl4,
                        istl_1: ov.istl_1,
                        istl_2: ov.istl_2,
                        istl_3: ov.istl_3,
                        istl_4: ov.istl_4,
                        istl_5: ov.istl_5,
                        istl_6: ov.istl_6,
                        istl_7: ov.istl_7,
                        istl_8: ov.istl_8,
                        istl_9: ov.istl_9,
                        istl_10: ov.istl_10,
                        istl_11: ov.istl_11,
                        istl_12: ov.istl_12,
                        istl_13: ov.istl_13,
                        istl_14: ov.istl_14,
                        istl_15: ov.istl_15,
                        istl_16: ov.istl_16,
                        istl_17: ov.istl_17,
                        istl_18: ov.istl_18,
                        istl_19: ov.istl_19,
                        istl_20: ov.istl_20,
                        video: ov.video,
                        gif: ov.gif,
                        gif2d: ov.gif2d,
                        workorder: ov.workorder,
                        treatment: ov.treatment,
                        gif_splint: ov.gif_splint,
                        zip: ov.zip,
                        status: action.data.status,
                        status_minivints: ov.status_minivints,
                        status_template: ov.status_template,
                        status_equipment: ov.status_equipment,
                        status_equipment_2d: ov.status_equipment_2d,
                        status_braces: ov.status_braces,
                        status_aligners: ov.status_aligners,
                        status_tray: ov.status_tray,
                        status_splint: ov.status_splint,
                        public: ov.public,
                        version: ov.version,
                        shift: true
                    };
                    yield fetch(`${GATEWAY_HOST}/orderversions`, {
                        method: 'POST',
                        body: JSON.stringify(body3),
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });
                }
            }
        }

        const dataOvRes = yield fetch(`${GATEWAY_HOST}/orderversions`, {
            method: 'POST',
            body: JSON.stringify(body2),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        const dataOv = yield dataOvRes.json();
        const res = yield fetch(
            `${GATEWAY_HOST}/orders${isEmpty(orderId) ? '' : `/${orderId}`}`,
            {
                method: isEmpty(orderId) ? 'POST' : 'PUT',
                body: JSON.stringify({ ...body, current: dataOv.id }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        const data = yield res.json();

        yield put(onAllOrdersLoad());
        yield put(selectOrder(data.id, 'latest'));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* loadUserDataSaga() {
    try {
        const token = yield select(selectToken);

        const res = yield fetch(`${GATEWAY_HOST}/users/me`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const data = yield res.json();
        if (data.stl) {
            const res2 = yield fetch(`${GATEWAY_HOST}/stls/${data.stl}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data2 = yield res2.json();
            data.stl = data2;
        }
        // yield put(loadLocaleSuccess(data2));
        yield put(loadUserDataSuccess(data));
        yield put(loadLocale());
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* loadOrdersSaga(action) {
    try {
        const token = yield select(selectToken);
        const profile = yield select(selectProfile);
        const decodedToken = jwtDecode(token);
        const order = yield select(selectOrderState);
        const pagination = `_start=${(action.pagination.current - 1) *
            action.pagination.pageSize}&_limit=${action.pagination.pageSize}`;
        let sorter = '';
        let filter =
            profile.role.name === 'doctor'
                ? `&doctor_eq=${decodedToken.id}`
                : '';

        if (!isEmpty(action.doctorId)) {
            filter = `&doctor_eq=${action.doctorId}`;
        }

        if (!isEmpty(order.search)) {
            filter += `&patient_contains=${order.search}`;
        }

        if (!isEmpty(order.status)) {
            let operator = 'in';
            if (order.status.operator !== 'eq') {
                operator = 'nin';
            }
            filter += `&status_${operator}=${order.status.value}&status_nin=DRAFT`;
            filter += `&status_${operator}=DECLINED&status_nin=DRAFT`;
        } else {
            filter += `&status_nin=DRAFT`;
        }

        if (!isEmpty(order.sorter)) {
            sorter = `&_sort=${order.sorter.field}:${
                order.sorter.order === 'descend' ? 'DESC' : 'ASC'
            }`;
        }
        const res = yield fetch(
            `${GATEWAY_HOST}/orders?${pagination}${sorter}${filter}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const data = yield res.json();
        const res2 = yield fetch(`${GATEWAY_HOST}/orders/count`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const data2 = yield res2.json();
        yield put(loadOrdersSuccess(data));
        yield put(loadOrdersCountSuccess(data2));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* loadDoctorsSaga(action) {
    try {
        const token = yield select(selectToken);
        const pagination = `_start=${(action.pagination.current - 1) *
            action.pagination.pageSize}&_limit=${action.pagination.pageSize}`;
        let sorter = '';
        let filter = '';

        if (!isEmpty(action.sorter)) {
            sorter = `&_sort=${action.sorter.field}:${
                action.sorter.order === 'descend' ? 'DESC' : 'ASC'
            }`;
        }

        if (!isEmpty(action.filters)) {
            const tempFilter = action.filters[0];
            filter = `&${tempFilter.field}_${tempFilter.operator}=${tempFilter.value}`;
        }

        const res = yield fetch(
            `${GATEWAY_HOST}/users?${pagination}${sorter}${filter}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const data = yield res.json();
        const res2 = yield fetch(`${GATEWAY_HOST}/users/count`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const data2 = yield res2.json();
        yield put(loadDoctorsSuccess(data));
        yield put(loadDoctorsCountSuccess(data2));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* loadTechsSaga() {
    try {
        const token = yield select(selectToken);
        const pagination = `_limit=-1`;

        const res = yield fetch(
            `${GATEWAY_HOST}/users?${pagination}&role.name_in=technician&role.name_in=manager`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const data = yield res.json();
        yield put(loadTechsSuccess(data));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* loadLocaleSaga(action) {
    try {
        let token = yield select(selectToken);
        if (isEmpty(token)) {
            token = action.token;
        }
        const profile = yield select(selectProfile);
        let localeId = isEmpty(token) ? 'ru' : profile.locale;
        const { locale } = action;

        if (locale === 'ru') {
            localeId = locale;
        } else if (locale === 'en') {
            localeId = locale;
        }

        let headers = {};

        if (!isEmpty(token)) {
            headers = {
                Authorization: `Bearer ${token}`
            };
        }

        const res = yield fetch(
            `${GATEWAY_HOST}/texts?_locale=${localeId}&_limit=500`,
            {
                headers
            }
        );
        const data = yield res.json();
        yield put(loadLocaleSuccess(data));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* updateProfileSaga(action) {
    try {
        const token = yield select(selectToken);
        const profile = yield select(selectProfile);
        const body = {
            first_name: action.firstName,
            last_name: action.lastName,
            middle_name: action.middleName,
            locale: action.locale,
            notifications: action.notifications,
            email: action.email,
            phone: action.phone
        };

        const res = yield fetch(`${GATEWAY_HOST}/users/${profile.id}`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        const data = yield res.json();
        yield put(loadLocale());
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* loadNewComments(action) {
    try {
        const token = yield select(selectToken);
        const message = JSON.parse(action.message);
        const res = yield fetch(
            `${GATEWAY_HOST}/comments?order_id=${message.comment.order_id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const data = yield res.json();
        yield put(onNewCommentSuccess(data));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* loadOrdersMessagesSaga(action) {
    try {
        const token = yield select(selectToken);
        const decodedToken = jwtDecode(token);
        const profile = yield select(selectProfile);
        const doctorFilter =
            profile.role.name === 'doctor'
                ? `?doctor_eq=${decodedToken.id}&_limit=1000`
                : '?_limit=1000';
        const res = yield fetch(`${GATEWAY_HOST}/orders${doctorFilter}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const unreadRes = yield fetch(
            `${GATEWAY_HOST}/orders/updated?user_id=${decodedToken.id}&role=${profile.role.name}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const unread = yield unreadRes.json();
        const data = yield res.json();
        yield put(loadOrdersMessagesSuccess(data, unread));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* organisationCreateSaga(action) {
    try {
        const token = yield select(selectToken);
        const profile = yield select(selectProfile);
        const body = {
            name: action.organisationName,
            doctor: profile.id
        };

        const res = yield fetch(`${GATEWAY_HOST}/organisations`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        yield res.json();
        yield put(organisationLoad());
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* organisationRemoveSaga(action) {
    try {
        const token = yield select(selectToken);
        // const profile = yield select(selectProfile);

        const res = yield fetch(
            `${GATEWAY_HOST}/organisations/${action.organisationId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        yield res.json();
        yield put(organisationLoad());
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* organisationLoadSaga() {
    try {
        const token = yield select(selectToken);
        const profile = yield select(selectProfile);

        const res = yield fetch(
            `${GATEWAY_HOST}/organisations?doctor=${profile.id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        const data = yield res.json();
        yield put(organisationLoadSuccess(data));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* searchDoctorsSaga(action) {
    try {
        const token = yield select(selectToken);
        const pagination = '_start=0&_limit=200';
        const sorter = '&_sort=last_name:ASC';
        let filter = '&role.name=doctor';
        if (!isEmpty(action.search)) {
            filter += `&last_name_contains=${action.search}`;
        }

        const res = yield fetch(
            `${GATEWAY_HOST}/users?${pagination}${sorter}${filter}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const data = yield res.json();
        yield put(searchDoctorsSuccess(data));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* searchTechniciansSaga(action) {
    try {
        const token = yield select(selectToken);
        const pagination = '_start=0&_limit=100';
        const sorter = '&_sort=last_name:ASC';
        let filter = '&role.name_in=technician&role.name_in=manager';
        if (!isEmpty(action.search)) {
            filter += `&last_name_contains=${action.search}`;
        }

        const res = yield fetch(
            `${GATEWAY_HOST}/users?${pagination}${sorter}${filter}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const data = yield res.json();
        yield put(searchTechniciansSuccess(data));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* approveOrderStageSaga(action) {
    try {
        const token = yield select(selectToken);
        const { orderId } = action.data;
        const body = {
            status: action.data.status,
            status_minivints: action.data.status_minivints,
            status_template: action.data.status_template,
            status_equipment: action.data.status_equipment,
            status_equipment_2d: action.data.status_equipment_2d,
            status_braces: action.data.status_braces,
            status_aligners: action.data.status_aligners,
            status_splint: action.data.status_splint,
            latest: action.data.design_date
        };

        const body2 = {
            order: orderId,
            design_date: action.data.design_date,
            // image_1: action.data.image1,
            // image_2: action.data.image2,
            // image_3: action.data.image3,
            // image_4: action.data.image4,
            // image_5: action.data.image5,
            img_1: action.data.image1,
            img_2: action.data.image2,
            img_3: action.data.image3,
            img_4: action.data.image4,
            img_5: action.data.image5,
            // minivints
            minivints_image_1: action.data.minivintsImage1,
            minivints_image_2: action.data.minivintsImage2,
            minivints_image_3: action.data.minivintsImage3,
            minivints_image_4: action.data.minivintsImage4,
            minivints_image_5: action.data.minivintsImage5,
            minivints_image_6: action.data.minivintsImage6,
            minivints_image_7: action.data.minivintsImage7,
            minivints_image_8: action.data.minivintsImage8,
            minivints_image_9: action.data.minivintsImage9,
            minivints_image_10: action.data.minivintsImage10,
            minivints_label_1: action.data.minivintsLabel1,
            minivints_label_2: action.data.minivintsLabel2,
            minivints_label_3: action.data.minivintsLabel3,
            minivints_label_4: action.data.minivintsLabel4,
            minivints_label_5: action.data.minivintsLabel5,
            minivints_label_6: action.data.minivintsLabel6,
            minivints_label_7: action.data.minivintsLabel7,
            minivints_label_8: action.data.minivintsLabel8,
            minivints_label_9: action.data.minivintsLabel9,
            minivints_label_10: action.data.minivintsLabel10,
            // template
            template_image_1: action.data.templateImage1,
            template_image_2: action.data.templateImage2,
            template_image_3: action.data.templateImage3,
            template_image_4: action.data.templateImage4,
            template_image_5: action.data.templateImage5,
            template_image_6: action.data.templateImage6,
            template_image_7: action.data.templateImage7,
            template_image_8: action.data.templateImage8,
            template_image_9: action.data.templateImage9,
            template_image_10: action.data.templateImage10,
            template_label_1: action.data.templateLabel1,
            template_label_2: action.data.templateLabel2,
            template_label_3: action.data.templateLabel3,
            template_label_4: action.data.templateLabel4,
            template_label_5: action.data.templateLabel5,
            template_label_6: action.data.templateLabel6,
            template_label_7: action.data.templateLabel7,
            template_label_8: action.data.templateLabel8,
            template_label_9: action.data.templateLabel9,
            template_label_10: action.data.templateLabel10,
            // tool
            tool_image_1: action.data.toolImage1,
            tool_image_2: action.data.toolImage2,
            tool_image_3: action.data.toolImage3,
            tool_image_4: action.data.toolImage4,
            tool_image_5: action.data.toolImage5,
            tool_image_6: action.data.toolImage6,
            tool_image_7: action.data.toolImage7,
            tool_image_8: action.data.toolImage8,
            tool_image_9: action.data.toolImage9,
            tool_image_10: action.data.toolImage10,
            tool_label_1: action.data.toolLabel1,
            tool_label_2: action.data.toolLabel2,
            tool_label_3: action.data.toolLabel3,
            tool_label_4: action.data.toolLabel4,
            tool_label_5: action.data.toolLabel5,
            tool_label_6: action.data.toolLabel6,
            tool_label_7: action.data.toolLabel7,
            tool_label_8: action.data.toolLabel8,
            tool_label_9: action.data.toolLabel9,
            tool_label_10: action.data.toolLabel10,
            // tool 2d
            tool_2d_image_1: action.data.tool2dImage1,
            tool_2d_image_2: action.data.tool2dImage2,
            tool_2d_image_3: action.data.tool2dImage3,
            tool_2d_image_4: action.data.tool2dImage4,
            tool_2d_image_5: action.data.tool2dImage5,
            tool_2d_image_6: action.data.tool2dImage6,
            tool_2d_image_7: action.data.tool2dImage7,
            tool_2d_image_8: action.data.tool2dImage8,
            tool_2d_image_9: action.data.tool2dImage9,
            tool_2d_image_10: action.data.tool2dImage10,
            tool_2d_label_1: action.data.tool2dLabel1,
            tool_2d_label_2: action.data.tool2dLabel2,
            tool_2d_label_3: action.data.tool2dLabel3,
            tool_2d_label_4: action.data.tool2dLabel4,
            tool_2d_label_5: action.data.tool2dLabel5,
            tool_2d_label_6: action.data.tool2dLabel6,
            tool_2d_label_7: action.data.tool2dLabel7,
            tool_2d_label_8: action.data.tool2dLabel8,
            tool_2d_label_9: action.data.tool2dLabel9,
            tool_2d_label_10: action.data.tool2dLabel10,
            // virtual setup
            vs_image_1: action.data.vsImage1,
            vs_image_2: action.data.vsImage2,
            vs_image_3: action.data.vsImage3,
            vs_image_4: action.data.vsImage4,
            vs_image_5: action.data.vsImage5,
            vs_image_6: action.data.vsImage6,
            vs_image_7: action.data.vsImage7,
            vs_image_8: action.data.vsImage8,
            vs_image_9: action.data.vsImage9,
            vs_image_10: action.data.vsImage10,
            vs_image_11: action.data.vsImage11,
            vs_image_12: action.data.vsImage12,
            vs_image_13: action.data.vsImage13,
            vs_image_14: action.data.vsImage14,
            vs_image_15: action.data.vsImage15,
            vs_label_1: action.data.vsLabel1,
            vs_label_2: action.data.vsLabel2,
            vs_label_3: action.data.vsLabel3,
            vs_label_4: action.data.vsLabel4,
            vs_label_5: action.data.vsLabel5,
            vs_label_6: action.data.vsLabel6,
            vs_label_7: action.data.vsLabel7,
            vs_label_8: action.data.vsLabel8,
            vs_label_9: action.data.vsLabel9,
            vs_label_10: action.data.vsLabel10,
            vs_label_11: action.data.vsLabel11,
            vs_label_12: action.data.vsLabel12,
            vs_label_13: action.data.vsLabel13,
            vs_label_14: action.data.vsLabel14,
            vs_label_15: action.data.vsLabel15,
            // splint
            splint_image_1: action.data.splintImage1,
            splint_image_2: action.data.splintImage2,
            splint_image_3: action.data.splintImage3,
            splint_image_4: action.data.splintImage4,
            splint_image_5: action.data.splintImage5,
            splint_image_6: action.data.splintImage6,
            splint_image_7: action.data.splintImage7,
            splint_image_8: action.data.splintImage8,
            splint_image_9: action.data.splintImage9,
            splint_image_10: action.data.splintImage10,
            splint_label_1: action.data.splintLabel1,
            splint_label_2: action.data.splintLabel2,
            splint_label_3: action.data.splintLabel3,
            splint_label_4: action.data.splintLabel4,
            splint_label_5: action.data.splintLabel5,
            splint_label_6: action.data.splintLabel6,
            splint_label_7: action.data.splintLabel7,
            splint_label_8: action.data.splintLabel8,
            splint_label_9: action.data.splintLabel9,
            splint_label_10: action.data.splintLabel10,
            // labels
            label_1: action.data.label1,
            label_2: action.data.label2,
            label_3: action.data.label3,
            label_4: action.data.label4,
            label_5: action.data.label5,
            // stl_1: action.data.stl1,
            // stl_2: action.data.stl2,
            // stl_3: action.data.stl3,
            // stl_4: action.data.stl4,
            istl_1: action.data.stl1,
            istl_2: action.data.stl2,
            istl_3: action.data.stl3,
            istl_4: action.data.stl4,
            istl_5: action.data.stl5,
            istl_6: action.data.stl6,
            istl_7: action.data.stl7,
            istl_8: action.data.stl8,
            istl_9: action.data.stl9,
            istl_10: action.data.stl10,
            istl_11: action.data.stl11,
            istl_12: action.data.stl12,
            istl_13: action.data.stl13,
            istl_14: action.data.stl14,
            istl_15: action.data.stl15,
            istl_16: action.data.stl16,
            istl_17: action.data.stl17,
            istl_18: action.data.stl18,
            istl_19: action.data.stl19,
            istl_20: action.data.stl20,
            video: action.data.video,
            gif: action.data.gif,
            gif2d: action.data.gif2d,
            workorder: action.data.workorder,
            treatment: action.data.treatment,
            gif_splint: action.data.gif_splint,
            zip: action.data.zip,
            status: action.data.status,
            status_minivints: action.data.status_minivints,
            status_template: action.data.status_template,
            status_equipment: action.data.status_equipment,
            status_equipment_2d: action.data.status_equipment_2d,
            status_braces: action.data.status_braces,
            status_aligners: action.data.status_aligners,
            status_tray: action.data.status_tray,
            status_splint: action.data.status_splint,
            public: action.data.public,
            version: action.data.version
        };

        console.log('body2', body2);

        const dataOvRes = yield fetch(`${GATEWAY_HOST}/orderversions`, {
            method: 'POST',
            body: JSON.stringify(body2),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const dataOv = yield dataOvRes.json();

        yield fetch(
            `${GATEWAY_HOST}/orders${isEmpty(orderId) ? '' : `/${orderId}`}`,
            {
                method: isEmpty(orderId) ? 'POST' : 'PUT',
                body: JSON.stringify({ ...body, current: dataOv.id }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        // yield res.json();

        const author = `${action.data.doctor.last_name} ${action.data.doctor.first_name} ${action.data.doctor.middle_name}`;
        const { comment } = action.data;
        const date = moment.utc().format();
        // orderId, revisionId, message, author, date, doctorId
        yield put(
            saveMessage(
                `${orderId}`,
                'latest',
                comment,
                author,
                date,
                action.data.doctor.id
            )
        );
        yield put(selectOrder(orderId, 'latest'));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}

function* rootSaga() {
    console.log('SAGA', 'rootSaga');
    // yield spawn(wsSagas);
    yield all([
        takeLatest(actionTypes.CONNECT_TO_BUS, wsSagas),
        takeLatest(actionTypes.DELETE_ORDER, deleteOrderSaga),
        takeLatest(actionTypes.SAVE_MESSAGE, saveMessageSaga),
        takeLatest(actionTypes.SAVE_INTERNAL_MESSAGE, saveInternalMessageSaga),
        takeLatest(actionTypes.LOAD_USER_DATA, loadUserDataSaga),
        takeLatest(actionTypes.LOAD_ORDERS, loadOrdersSaga),
        takeLatest(actionTypes.SAVE_ORDER_DATA, saveOrderDataSaga),
        takeLatest(actionTypes.SELECT_ORDER, selectOrderSaga),
        takeLatest(actionTypes.SELECT_ORDER_BASE, selectOrderBaseSaga),
        takeLatest(actionTypes.SELECT_DOCTOR, selectDoctorSaga),
        takeLatest(actionTypes.LOAD_DOCTORS, loadDoctorsSaga),
        takeLatest(actionTypes.LOAD_TECHS, loadTechsSaga),
        takeLatest(actionTypes.LOAD_LOCALE, loadLocaleSaga),
        takeLatest(actionTypes.PROFILE_UPDATE, updateProfileSaga),
        // takeLatest(actionTypes.NEW_MESSAGE, loadOrdersMessagesSaga),
        takeLatest(actionTypes.NEW_COMMENT, loadNewComments),
        takeLatest(actionTypes.LOAD_ORDERS_MESSAGES, loadOrdersMessagesSaga),
        takeLatest(actionTypes.ORGANISATION_REMOVE, organisationRemoveSaga),
        takeLatest(actionTypes.ORGANISATION_CREATE, organisationCreateSaga),
        takeLatest(actionTypes.ORGANISATION_LOAD, organisationLoadSaga),
        takeLatest(actionTypes.SEARCH_DOCTORS, searchDoctorsSaga),
        takeLatest(actionTypes.SEARCH_TECHNICIANS, searchTechniciansSaga),
        takeLatest(actionTypes.SELECT_MESSAGE_ORDER, selectMessageOrderSaga),
        // takeLatest(actionTypes.USER_ENTRY, loadUsersSaga),
        // takeLatest(actionTypes.LOAD_USERS, loadUsersSaga),
        // takeLatest(actionTypes.LOAD_USERS_FILTERS, loadUsersSaga),
        // takeLatest(actionTypes.LOAD_USERS_ROLE_CHANGE, loadUsersSaga),
        // takeLatest(actionTypes.LOAD_USERS_SEARCH_CHANGE, loadUsersSaga),
        takeLatest(actionTypes.LOAD_ALL_ORDERS, loadAllOrdersSaga),
        takeLatest(actionTypes.LOAD_ALL_ORDERS_FILTERS, loadAllOrdersSaga),
        takeLatest(
            actionTypes.LOAD_ALL_ORDERS_SEARCH_CHANGE,
            loadAllOrdersSaga
        ),
        takeLatest(actionTypes.CREATE_NEW_ORDER, createNewOrderSaga),
        takeLatest(actionTypes.APPROVE_ORDER_STAGE, approveOrderStageSaga),
        takeLatest(actionTypes.SAVE_STL_SETTINGS, onSaveStlSettingsSaga)
        // takeLatest(actionTypes.UPDATE_ORDER, loadUpdatedOrderSaga)
    ]);
}

export default rootSaga;
