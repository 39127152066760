export const actionTypes = {
    LOAD_TECHS: 'LOAD_TECHS',
    LOAD_TECHS_SUCCESS: 'LOAD_TECHS_SUCCESS',
    SELECT_TECH: 'SELECT_TECH',
    SELECT_MANAGER: 'SELECT_MANAGER',
    CONNECT_TO_BUS: 'CONNECT_TO_BUS',
    SAVE_STL_SETTINGS: 'SAVE_STL_SETTINGS',
    CHANGE_NEW_ORDER_UPLOAD_STATUS: 'CHANGE_NEW_ORDER_UPLOAD_STATUS',
    CHANGE_NEW_ORDER_FILE_UPLOAD_STATUS: 'CHANGE_NEW_ORDER_FILE_UPLOAD_STATUS',
    CHANGE_NEW_ORDER_VIDEO_UPLOAD_STATUS:
        'CHANGE_NEW_ORDER_VIDEO_UPLOAD_STATUS',
    CHANGE_NEW_ORDER_GIF_UPLOAD_STATUS: 'CHANGE_NEW_ORDER_GIF_UPLOAD_STATUS',
    CHANGE_NEW_ORDER_GIF_2D_UPLOAD_STATUS:
        'CHANGE_NEW_ORDER_GIF_2D_UPLOAD_STATUS',
    CHANGE_NEW_ORDER_WORKORDER_UPLOAD_STATUS:
        'CHANGE_NEW_ORDER_WORKORDER_UPLOAD_STATUS',
    CHANGE_NEW_ORDER_TREATMENT_UPLOAD_STATUS:
        'CHANGE_NEW_ORDER_TREATMENT_UPLOAD_STATUS',
    CHANGE_NEW_ORDER_GIF_SPLINT_UPLOAD_STATUS:
        'CHANGE_NEW_ORDER_GIF_SPLINT_UPLOAD_STATUS',
    CHANGE_NEW_ORDER_ZIP_UPLOAD_STATUS: 'CHANGE_NEW_ORDER_ZIP_UPLOAD_STATUS',
    CHANGE_MODEL_MODE: 'CHANGE_MODEL_MODE',
    CHANGE_IMAGE_LIST: 'CHANGE_IMAGE_LIST',
    CLOSE_NEW_ORDER: 'CLOSE_NEW_ORDER',
    FAILURE: 'FAILURE',
    SELECT_DOCTOR: 'SELECT_DOCTOR',
    DELETE_ORDER: 'DELETE_ORDER',
    SELECT_DOCTOR_SUCCESS: 'SELECT_DOCTOR_SUCCESS',
    LOAD_DOCTORS: 'LOAD_DOCTORS',
    LOAD_DOCTORS_SUCCESS: 'LOAD_DOCTORS_SUCCESS',
    LOAD_DOCTORS_COUNT_SUCCESS: 'LOAD_DOCTORS_COUNT_SUCCESS',
    LOAD_USER_DATA: 'LOAD_USER_DATA',
    LOAD_USER_DATA_SUCCESS: 'LOAD_USER_DATA_SUCCESS',
    LOAD_ORDERS: 'LOAD_ORDERS',
    LOAD_ORDERS_SUCCESS: 'LOAD_ORDERS_SUCCESS',
    LOAD_ORDER_SUCCESS: 'LOAD_ORDER_SUCCESS',
    LOAD_ORDERS_COUNT_SUCCESS: 'LOAD_ORDERS_COUNT_SUCCESS',
    OPEN_NEW_ORDER: 'OPEN_NEW_ORDER',
    OPEN_NEW_VERSION_ORDER: 'OPEN_NEW_VERSION_ORDER',
    OPEN_EDIT_ORDER: 'OPEN_EDIT_ORDER',
    OPEN_CREATE_ORDER: 'OPEN_CREATE_ORDER',
    SAVE_TOKEN: 'SAVE_TOKEN',
    SAVE_MESSAGE: 'SAVE_MESSAGE',
    UPLOAD_MESSAGE_IMAGE: 'UPLOAD_MESSAGE_IMAGE',
    UPLOAD_INTERNAL_MESSAGE_IMAGE: 'UPLOAD_INTERNAL_MESSAGE_IMAGE',
    SAVE_INTERNAL_MESSAGE: 'SAVE_INTERNAL_MESSAGE',
    SAVE_MESSAGE_SUCCESS: 'SAVE_MESSAGE_SUCCESS',
    SAVE_INTERNAL_MESSAGE_SUCCESS: 'SAVE_INTERNAL_MESSAGE_SUCCESS',
    NEW_MESSAGE: 'NEW_MESSAGE',
    NEW_COMMENT: 'NEW_COMMENT',
    NEW_COMMENT_SUCCESS: 'NEW_COMMENT_SUCCESS',
    SAVE_REFRESH_TOKEN: 'SAVE_REFRESH_TOKEN',
    SAVE_USER_DATA: 'SAVE_USER_DATA',
    SAVE_ORDER_DATA: 'SAVE_ORDER_DATA',
    SELECT_ORDER: 'SELECT_ORDER',
    SELECT_ORDER_BASE: 'SELECT_ORDER_BASE',
    PROFILE_SAVE: 'PROFILE_SAVE',
    PROFILE_UPDATE: 'PROFILE_UPDATE',
    SAVE_ROUTE: 'SAVE_ROUTE',
    NEW_FORM_STATUS_CHANGED: 'NEW_FORM_STATUS_CHANGED',
    NEW_FORM_DOCTOR_CHANGED: 'NEW_FORM_DOCTOR_CHANGED',
    NEW_FORM_TECHNICIAN_CHANGED: 'NEW_FORM_TECHNICIAN_CHANGED',
    NEW_FORM_MANAGER_CHANGED: 'NEW_FORM_MANAGER_CHANGED',
    NEW_FORM_ADDED_CHANGED: 'NEW_FORM_ADDED_CHANGED',
    NEW_FORM_MINIVINTS_ADDED_CHANGED: 'NEW_FORM_MINIVINTS_ADDED_CHANGED',
    NEW_FORM_TEMPLATE_ADDED_CHANGED: 'NEW_FORM_TEMPLATE_ADDED_CHANGED',
    NEW_FORM_TOOL_ADDED_CHANGED: 'NEW_FORM_TOOL_ADDED_CHANGED',
    NEW_FORM_TOOL_2D_ADDED_CHANGED: 'NEW_FORM_TOOL_2D_ADDED_CHANGED',
    NEW_FORM_VS_ADDED_CHANGED: 'NEW_FORM_VS_ADDED_CHANGED',
    NEW_FORM_SPLINT_ADDED_CHANGED: 'NEW_FORM_SPLINT_ADDED_CHANGED',
    NEW_FORM_LABEL_CHANGED: 'NEW_FORM_LABEL_CHANGED',
    NEW_FORM_ORG_CHANGED: 'NEW_FORM_ORG_CHANGED',
    ORDERS_CHANGE_FILTER: 'ORDERS_CHANGE_FILTER',
    ORDERS_CHANGE_SORTER: 'ORDERS_CHANGE_SORTER',
    ORDERS_CHANGE_SEARCH: 'ORDERS_CHANGE_SEARCH',
    LOAD_LOCALE: 'LOAD_LOCALE',
    LOAD_LOCALE_SUCCESS: 'LOAD_LOCALE_SUCCESS',
    LOAD_ALL_MESSAGES: 'LOAD_ALL_MESSAGES',
    LOAD_ORDERS_MESSAGES: 'LOAD_ORDERS_MESSAGES',
    LOAD_ORDERS_MESSAGES_SUCCESS: 'LOAD_ORDERS_MESSAGES_SUCCESS',
    SELECT_MESSAGE_ORDER: 'SELECT_MESSAGE_ORDER',
    ORGANISATION_CREATE: 'ORGANISATION_CREATE',
    ORGANISATION_REMOVE: 'ORGANISATION_REMOVE',
    ORGANISATION_LOAD: 'ORGANISATION_LOAD',
    ORGANISATION_LOAD_SUCCESS: 'ORGANISATION_LOAD_SUCCESS',
    SEARCH_DOCTORS: 'SEARCH_DOCTORS',
    SEARCH_DOCTORS_SUCCESS: 'SEARCH_DOCTORS_SUCCESS',
    SEARCH_TECHNICIANS: 'SEARCH_TECHNICIANS',
    SEARCH_TECHNICIANS_SUCCESS: 'SEARCH_TECHNICIANS_SUCCESS',
    USER_ENTRY: 'USER_ENTRY',
    LOAD_USERS: 'LOAD_USERS',
    LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
    LOAD_USERS_FILTERS: 'LOAD_USERS_FILTERS',
    LOAD_USERS_ROLE_CHANGE: 'LOAD_USERS_ROLE_CHANGE',
    LOAD_USERS_SEARCH_CHANGE: 'LOAD_USERS_SEARCH_CHANGE',
    // ORDERS
    LOAD_ALL_ORDERS: 'LOAD_ALL_ORDERS',
    LOAD_ALL_ORDERS_SUCCESS: 'LOAD_ALL_ORDERS_SUCCESS',
    LOAD_ALL_ORDERS_FILTERS: 'LOAD_USERS_FILTERS',
    LOAD_ALL_ORDERS_SEARCH_CHANGE: 'LOAD_USERS_SEARCH_CHANGE',
    // CREATE NEW ORDER
    CREATE_NEW_ORDER: 'CREATE_NEW_ORDER',
    // RECEIVE NOTIFICATION ABOUT ORDER
    NEW_ORDER: 'NEW_ORDER',
    UPDATE_ORDER: 'UPDATE_ORDER',
    LOAD_UPDATED_ORDER_SUCCES: 'LOAD_UPDATED_ORDER_SUCCES',
    // STATUS CHANGES
    APPROVE_ORDER_STAGE: 'APPROVE_ORDER_STAGE'
};

export function onSaveStlSettings(
    newModelColor,
    newModelAfterColor,
    newMinivintsColor,
    newTemplateColor,
    newToolColor,
    newTrayColor,
    newModelShineness,
    newModelAfterShineness,
    newMinivintsShineness,
    newTemplateShineness,
    newToolShineness,
    newTrayShineness,
    newTrayOpacity,
    newSplintColor,
    newSplintShineness,
    newSplintOpacity,
    newMaxillaryColor,
    newMaxillaryShineness
) {
    return {
        type: actionTypes.SAVE_STL_SETTINGS,
        newModelColor,
        newModelAfterColor,
        newMinivintsColor,
        newTemplateColor,
        newToolColor,
        newTrayColor,
        newModelShineness,
        newModelAfterShineness,
        newMinivintsShineness,
        newTemplateShineness,
        newToolShineness,
        newTrayShineness,
        newTrayOpacity,
        newSplintColor,
        newSplintShineness,
        newSplintOpacity,
        newMaxillaryColor,
        newMaxillaryShineness
    };
}

export function connectToBus() {
    return {
        type: actionTypes.CONNECT_TO_BUS
    };
}

export function approveOrderStage(order) {
    return {
        type: actionTypes.APPROVE_ORDER_STAGE,
        data: order
    };
}

export function onChangeModelMode(value) {
    return {
        type: actionTypes.CHANGE_MODEL_MODE,
        modelMode: value
    };
}

export function onAllOrdersLoad() {
    return {
        type: actionTypes.LOAD_ALL_ORDERS
    };
}

export function onAllOrdersLoadSuccess(orders) {
    return {
        type: actionTypes.LOAD_ALL_ORDERS_SUCCESS,
        orders
    };
}

export function applySearchToAllOrders(search) {
    return {
        type: actionTypes.LOAD_ALL_ORDERS_SEARCH_CHANGE,
        search
    };
}

export function applyFiltersToAllOrders(pagination, sorter, filters) {
    return {
        type: actionTypes.LOAD_ALL_ORDERS_FILTERS,
        pagination,
        sorter,
        filters
    };
}

export function applyRoleToUser(role) {
    return {
        type: actionTypes.LOAD_USERS_ROLE_CHANGE,
        role
    };
}

export function applySearchToUser(search) {
    return {
        type: actionTypes.LOAD_USERS_SEARCH_CHANGE,
        search
    };
}

export function applyFiltersToUser(pagination, sorter, filters) {
    return {
        type: actionTypes.LOAD_USERS_FILTERS,
        pagination,
        sorter,
        filters
    };
}

export function onUsersLoad(pagination, sorter) {
    return {
        type: actionTypes.LOAD_USERS,
        pagination,
        sorter
    };
}

export function onUsersLoadSuccess(users) {
    return {
        type: actionTypes.LOAD_USERS_SUCCESS,
        users
    };
}

export function onUserEntry(user) {
    return {
        type: actionTypes.USER_ENTRY,
        user
    };
}

export function onNewMessage(message) {
    return {
        type: actionTypes.NEW_MESSAGE,
        message
    };
}

export function onNewComment(message) {
    return {
        type: actionTypes.NEW_COMMENT,
        message
    };
}

export function onNewCommentSuccess(data) {
    return {
        type: actionTypes.NEW_COMMENT_SUCCESS,
        data
    };
}

export function onUpdateOrder(message) {
    return {
        type: actionTypes.UPDATE_ORDER,
        message
    };
}

export function onloadUpdatedOrderSuccess(data) {
    return {
        type: actionTypes.LOAD_UPDATED_ORDER_SUCCES,
        data
    };
}

export function onNewOrder(message) {
    return {
        type: actionTypes.NEW_ORDER,
        message
    };
}

export function onSaveMessageSuccess() {
    return {
        type: actionTypes.SAVE_MESSAGE_SUCCESS
    };
}

export function onSaveInternalMessageSuccess() {
    return {
        type: actionTypes.SAVE_INTERNAL_MESSAGE_SUCCESS
    };
}

export function onSelectMessageOrder(order) {
    return {
        type: actionTypes.SELECT_MESSAGE_ORDER,
        order
    };
}

export function searchTechnicians(search) {
    return {
        type: actionTypes.SEARCH_TECHNICIANS,
        search
    };
}

export function searchTechniciansSuccess(technicians) {
    return {
        type: actionTypes.SEARCH_TECHNICIANS_SUCCESS,
        technicians
    };
}

export function searchDoctors(search) {
    return {
        type: actionTypes.SEARCH_DOCTORS,
        search
    };
}

export function searchDoctorsSuccess(doctors) {
    return {
        type: actionTypes.SEARCH_DOCTORS_SUCCESS,
        doctors
    };
}

export function organisationCreate(organisationName) {
    return {
        type: actionTypes.ORGANISATION_CREATE,
        organisationName
    };
}

export function organisationLoad() {
    return {
        type: actionTypes.ORGANISATION_LOAD
    };
}

export function organisationLoadSuccess(organisations) {
    return {
        type: actionTypes.ORGANISATION_LOAD_SUCCESS,
        organisations
    };
}

export function organisationRemoveSuccess(organisation) {
    return {
        type: actionTypes.ORGANISATION_CREATE_SUCCESS,
        organisation
    };
}

export function organisationRemove(organisationId) {
    return {
        type: actionTypes.ORGANISATION_REMOVE,
        organisationId
    };
}

export function loadOrdersMessages() {
    return {
        type: actionTypes.LOAD_ORDERS_MESSAGES
    };
}

export function loadOrdersMessagesSuccess(data, unread) {
    return {
        type: actionTypes.LOAD_ORDERS_MESSAGES_SUCCESS,
        data,
        unread
    };
}

export function updateProfile(profile) {
    const firstName = profile.first_name;
    const lastName = profile.last_name;
    const middleName = profile.middle_name;
    const { email, locale, phone, notifications } = profile;
    return {
        type: actionTypes.PROFILE_UPDATE,
        firstName,
        middleName,
        lastName,
        email,
        locale,
        notifications,
        phone
    };
}

export function loadLocale(locale, token) {
    return {
        type: actionTypes.LOAD_LOCALE,
        locale,
        token
    };
}

export function loadLocaleSuccess(texts) {
    return {
        type: actionTypes.LOAD_LOCALE_SUCCESS,
        texts
    };
}

export function onOrdersFiltersChanged(filter) {
    return {
        type: actionTypes.ORDERS_CHANGE_FILTER,
        filter
    };
}

export function onOrdersSearchChanged(search) {
    return {
        type: actionTypes.ORDERS_CHANGE_SEARCH,
        search
    };
}

export function onOrdersSorterChanged(sorter) {
    return {
        type: actionTypes.ORDERS_CHANGE_SORTER,
        sorter
    };
}

export function onNewFormLabelChanged(label, value, type) {
    return {
        type: actionTypes.NEW_FORM_LABEL_CHANGED,
        label,
        value,
        obj: type
    };
}

export function onNewFormDoctorChanged(doctor) {
    return {
        type: actionTypes.NEW_FORM_DOCTOR_CHANGED,
        doctor
    };
}

export function onNewFormTechnicianChanged(technician) {
    return {
        type: actionTypes.NEW_FORM_TECHNICIAN_CHANGED,
        technician
    };
}

export function onNewFormManagerChanged(manager) {
    return {
        type: actionTypes.NEW_FORM_MANAGER_CHANGED,
        manager
    };
}

export function onNewFormOrganisationChanged(organisation) {
    return {
        type: actionTypes.NEW_FORM_ORG_CHANGED,
        organisation
    };
}

export function onNewFormAddedChanged(added) {
    return {
        type: actionTypes.NEW_FORM_ADDED_CHANGED,
        added
    };
}

export function onNewFormMinivintsAddedChanged(added) {
    return {
        type: actionTypes.NEW_FORM_MINIVINTS_ADDED_CHANGED,
        added
    };
}

export function onNewFormTemplateAddedChanged(added) {
    return {
        type: actionTypes.NEW_FORM_TEMPLATE_ADDED_CHANGED,
        added
    };
}

export function onNewFormToolAddedChanged(added) {
    return {
        type: actionTypes.NEW_FORM_TOOL_ADDED_CHANGED,
        added
    };
}

export function onNewFormTool2dAddedChanged(added) {
    return {
        type: actionTypes.NEW_FORM_TOOL_2D_ADDED_CHANGED,
        added
    };
}

export function onNewFormVsAddedChanged(added) {
    return {
        type: actionTypes.NEW_FORM_VS_ADDED_CHANGED,
        added
    };
}

export function onNewFormSplintAddedChanged(added) {
    return {
        type: actionTypes.NEW_FORM_SPLINT_ADDED_CHANGED,
        added
    };
}

export function onNewFormStatusChanged(status, value) {
    return {
        type: actionTypes.NEW_FORM_STATUS_CHANGED,
        status,
        value
    };
}

export function loadDoctorsSuccess(data) {
    return {
        type: actionTypes.LOAD_DOCTORS_SUCCESS,
        data
    };
}

export function selectDoctor(doctorId) {
    return {
        type: actionTypes.SELECT_DOCTOR,
        doctorId
    };
}

export function selectDoctorSuccess(data) {
    return {
        type: actionTypes.SELECT_DOCTOR_SUCCESS,
        data
    };
}

export function loadDoctorsCountSuccess(data) {
    return {
        type: actionTypes.LOAD_DOCTORS_COUNT_SUCCESS,
        data
    };
}

export function onSelectTech(techId) {
    return {
        type: actionTypes.SELECT_TECH,
        data: techId
    };
}

export function onSelectManager(managerId) {
    return {
        type: actionTypes.SELECT_MANAGER,
        data: managerId
    };
}

export function loadTechs() {
    return {
        type: actionTypes.LOAD_TECHS
    };
}

export function loadTechsSuccess(data) {
    return {
        type: actionTypes.LOAD_TECHS_SUCCESS,
        data
    };
}

export function loadDoctors(pagination, sorter, filters) {
    return {
        type: actionTypes.LOAD_DOCTORS,
        pagination,
        sorter,
        filters
    };
}

export function saveMessage(
    orderId,
    revisionId,
    message,
    author,
    date,
    doctorId
) {
    return {
        type: actionTypes.SAVE_MESSAGE,
        message,
        author,
        date,
        orderId,
        revisionId,
        doctorId
    };
}

export function saveInternalMessage(
    orderId,
    revisionId,
    message,
    author,
    date,
    doctorId
) {
    return {
        type: actionTypes.SAVE_INTERNAL_MESSAGE,
        message,
        author,
        date,
        orderId,
        revisionId,
        doctorId
    };
}

export function deleteOrder(orderId) {
    return {
        type: actionTypes.DELETE_ORDER,
        orderId
    };
}

export function selectOrder(orderId, revisionId, reloadRaw, token) {
    let reload = reloadRaw;
    if (typeof reloadRaw === 'undefined') {
        reload = true;
    }
    return {
        type: actionTypes.SELECT_ORDER,
        orderId,
        revisionId,
        reload,
        token
    };
}

export function selectOrderBase(orderId, revisionId) {
    return {
        type: actionTypes.SELECT_ORDER_BASE,
        orderId,
        revisionId
    };
}

export function loadOrderSuccess(order) {
    return {
        type: actionTypes.LOAD_ORDER_SUCCESS,
        order
    };
}

export function saveOrderData(data) {
    return {
        type: actionTypes.SAVE_ORDER_DATA,
        data
    };
}

export function createNewOrder(data) {
    return {
        type: actionTypes.CREATE_NEW_ORDER,
        data
    };
}

export function changeImageList(fileList, status, index, type) {
    return {
        type: actionTypes.CHANGE_NEW_ORDER_UPLOAD_STATUS,
        index,
        fileList,
        status,
        obj: type
    };
}

export function changeFileList(fileList, status, index) {
    return {
        type: actionTypes.CHANGE_NEW_ORDER_FILE_UPLOAD_STATUS,
        index,
        fileList,
        status
    };
}

export function changeVideo(fileList, status) {
    return {
        type: actionTypes.CHANGE_NEW_ORDER_VIDEO_UPLOAD_STATUS,
        fileList,
        status
    };
}

export function changeGif(fileList, status) {
    return {
        type: actionTypes.CHANGE_NEW_ORDER_GIF_UPLOAD_STATUS,
        fileList,
        status
    };
}

export function changeGif2d(fileList, status) {
    return {
        type: actionTypes.CHANGE_NEW_ORDER_GIF_2D_UPLOAD_STATUS,
        fileList,
        status
    };
}

export function changeWorkorder(fileList, status) {
    return {
        type: actionTypes.CHANGE_NEW_ORDER_WORKORDER_UPLOAD_STATUS,
        fileList,
        status
    };
}

export function changeTreatment(fileList, status) {
    return {
        type: actionTypes.CHANGE_NEW_ORDER_TREATMENT_UPLOAD_STATUS,
        fileList,
        status
    };
}

export function changeGifSplint(fileList, status) {
    return {
        type: actionTypes.CHANGE_NEW_ORDER_GIF_SPLINT_UPLOAD_STATUS,
        fileList,
        status
    };
}
export function changeZip(fileList, status) {
    return {
        type: actionTypes.CHANGE_NEW_ORDER_ZIP_UPLOAD_STATUS,
        fileList,
        status
    };
}

export function changeUploadMessageImage(fileList, status) {
    return {
        type: actionTypes.UPLOAD_MESSAGE_IMAGE,
        fileList,
        status
    };
}

export function changeUploadInternalMessageImage(fileList, status) {
    return {
        type: actionTypes.UPLOAD_INTERNAL_MESSAGE_IMAGE,
        fileList,
        status
    };
}

export function changeNewOrderUploadStatus(status) {
    return {
        type: actionTypes.CHANGE_NEW_ORDER_UPLOAD_STATUS,
        status
    };
}

export function closeNewOrder() {
    return {
        type: actionTypes.CLOSE_NEW_ORDER
    };
}

export function openNewOrder(orderId, versionIncrement) {
    return {
        type: actionTypes.OPEN_NEW_ORDER,
        orderId,
        versionIncrement
    };
}

export function openNewVersionOrder(orderId) {
    return {
        type: actionTypes.OPEN_NEW_VERSION_ORDER,
        orderId
    };
}

export function openCreateOrder(orderId) {
    return {
        type: actionTypes.OPEN_CREATE_ORDER,
        orderId
    };
}

export function openEditOrder() {
    return {
        type: actionTypes.OPEN_EDIT_ORDER
    };
}

export function loadOrders(pagination, sorter, doctorId) {
    return {
        type: actionTypes.LOAD_ORDERS,
        pagination,
        sorter,
        doctorId
    };
}

export function loadOrdersSuccess(data) {
    return {
        type: actionTypes.LOAD_ORDERS_SUCCESS,
        data
    };
}

export function loadOrdersCountSuccess(data) {
    return {
        type: actionTypes.LOAD_ORDERS_COUNT_SUCCESS,
        data
    };
}

export function loadUserData(userId) {
    return {
        type: actionTypes.LOAD_USER_DATA,
        userId
    };
}

export function loadUserDataSuccess(data) {
    return {
        type: actionTypes.LOAD_USER_DATA_SUCCESS,
        data
    };
}

export function saveProfile(profile) {
    return {
        type: actionTypes.PROFILE_SAVE,
        profile
    };
}

export function saveRefreshToken(token) {
    return {
        type: actionTypes.SAVE_REFRESH_TOKEN,
        token
    };
}

export function saveToken(token) {
    return {
        type: actionTypes.SAVE_TOKEN,
        token
    };
}

export function saveRoute(route) {
    return {
        type: actionTypes.SAVE_ROUTE,
        route
    };
}

export function failure(error) {
    return {
        type: actionTypes.FAILURE,
        error
    };
}
