import { put, select } from 'redux-saga/effects';
import { selectToken } from '../selector';
import { failure, onAllOrdersLoad } from '../actions';
import { GATEWAY_HOST } from '../../utils/properties';
import { isEmpty } from '../../utils/common';

export default function* createNewOrderSaga(action) {
    try {
        const token = yield select(selectToken);
        const { orderId } = action.data;

        const body2 = {
            // order: data.id,
            design_date: action.data.design_date,
            img_1: action.data.image1,
            img_2: action.data.image2,
            img_3: action.data.image3,
            img_4: action.data.image4,
            img_5: action.data.image5,
            // minivints
            minivints_image_1: action.data.minivintsImage1,
            minivints_image_2: action.data.minivintsImage2,
            minivints_image_3: action.data.minivintsImage3,
            minivints_image_4: action.data.minivintsImage4,
            minivints_image_5: action.data.minivintsImage5,
            minivints_image_6: action.data.minivintsImage6,
            minivints_image_7: action.data.minivintsImage7,
            minivints_image_8: action.data.minivintsImage8,
            minivints_image_9: action.data.minivintsImage9,
            minivints_image_10: action.data.minivintsImage10,
            minivints_label_1: action.data.minivintsLabel1,
            minivints_label_2: action.data.minivintsLabel2,
            minivints_label_3: action.data.minivintsLabel3,
            minivints_label_4: action.data.minivintsLabel4,
            minivints_label_5: action.data.minivintsLabel5,
            minivints_label_6: action.data.minivintsLabel6,
            minivints_label_7: action.data.minivintsLabel7,
            minivints_label_8: action.data.minivintsLabel8,
            minivints_label_9: action.data.minivintsLabel9,
            minivints_label_10: action.data.minivintsLabel10,
            // template
            template_image_1: action.data.templateImage1,
            template_image_2: action.data.templateImage2,
            template_image_3: action.data.templateImage3,
            template_image_4: action.data.templateImage4,
            template_image_5: action.data.templateImage5,
            template_image_6: action.data.templateImage6,
            template_image_7: action.data.templateImage7,
            template_image_8: action.data.templateImage8,
            template_image_9: action.data.templateImage9,
            template_image_10: action.data.templateImage10,
            template_label_1: action.data.templateLabel1,
            template_label_2: action.data.templateLabel2,
            template_label_3: action.data.templateLabel3,
            template_label_4: action.data.templateLabel4,
            template_label_5: action.data.templateLabel5,
            template_label_6: action.data.templateLabel6,
            template_label_7: action.data.templateLabel7,
            template_label_8: action.data.templateLabel8,
            template_label_9: action.data.templateLabel9,
            template_label_10: action.data.templateLabel10,
            // tool
            tool_image_1: action.data.toolImage1,
            tool_image_2: action.data.toolImage2,
            tool_image_3: action.data.toolImage3,
            tool_image_4: action.data.toolImage4,
            tool_image_5: action.data.toolImage5,
            tool_image_6: action.data.toolImage6,
            tool_image_7: action.data.toolImage7,
            tool_image_8: action.data.toolImage8,
            tool_image_9: action.data.toolImage9,
            tool_image_10: action.data.toolImage10,
            tool_label_1: action.data.toolLabel1,
            tool_label_2: action.data.toolLabel2,
            tool_label_3: action.data.toolLabel3,
            tool_label_4: action.data.toolLabel4,
            tool_label_5: action.data.toolLabel5,
            tool_label_6: action.data.toolLabel6,
            tool_label_7: action.data.toolLabel7,
            tool_label_8: action.data.toolLabel8,
            tool_label_9: action.data.toolLabel9,
            tool_label_10: action.data.toolLabel10,
            // tool2d
            tool_2d_image_1: action.data.tool2dImage1,
            tool_2d_image_2: action.data.tool2dImage2,
            tool_2d_image_3: action.data.tool2dImage3,
            tool_2d_image_4: action.data.tool2dImage4,
            tool_2d_image_5: action.data.tool2dImage5,
            tool_2d_image_6: action.data.tool2dImage6,
            tool_2d_image_7: action.data.tool2dImage7,
            tool_2d_image_8: action.data.tool2dImage8,
            tool_2d_image_9: action.data.tool2dImage9,
            tool_2d_image_10: action.data.tool2dImage10,
            tool_2d_label_1: action.data.tool2dLabel1,
            tool_2d_label_2: action.data.tool2dLabel2,
            tool_2d_label_3: action.data.tool2dLabel3,
            tool_2d_label_4: action.data.tool2dLabel4,
            tool_2d_label_5: action.data.tool2dLabel5,
            tool_2d_label_6: action.data.tool2dLabel6,
            tool_2d_label_7: action.data.tool2dLabel7,
            tool_2d_label_8: action.data.tool2dLabel8,
            tool_2d_label_9: action.data.tool2dLabel9,
            tool_2d_label_10: action.data.tool2dLabel10,
            // virtual setup
            vs_image_1: action.data.vsImage1,
            vs_image_2: action.data.vsImage2,
            vs_image_3: action.data.vsImage3,
            vs_image_4: action.data.vsImage4,
            vs_image_5: action.data.vsImage5,
            vs_image_6: action.data.vsImage6,
            vs_image_7: action.data.vsImage7,
            vs_image_8: action.data.vsImage8,
            vs_image_9: action.data.vsImage9,
            vs_image_10: action.data.vsImage10,
            vs_image_11: action.data.vsImage11,
            vs_image_12: action.data.vsImage12,
            vs_image_13: action.data.vsImage13,
            vs_image_14: action.data.vsImage14,
            vs_image_15: action.data.vsImage15,
            vs_label_1: action.data.vsLabel1,
            vs_label_2: action.data.vsLabel2,
            vs_label_3: action.data.vsLabel3,
            vs_label_4: action.data.vsLabel4,
            vs_label_5: action.data.vsLabel5,
            vs_label_6: action.data.vsLabel6,
            vs_label_7: action.data.vsLabel7,
            vs_label_8: action.data.vsLabel8,
            vs_label_9: action.data.vsLabel9,
            vs_label_10: action.data.vsLabel10,
            vs_label_11: action.data.vsLabel11,
            vs_label_12: action.data.vsLabel12,
            vs_label_13: action.data.vsLabel13,
            vs_label_14: action.data.vsLabel14,
            vs_label_15: action.data.vsLabel15,
            // splint
            splint_image_1: action.data.splintImage1,
            splint_image_2: action.data.splintImage2,
            splint_image_3: action.data.splintImage3,
            splint_image_4: action.data.splintImage4,
            splint_image_5: action.data.splintImage5,
            splint_image_6: action.data.splintImage6,
            splint_image_7: action.data.splintImage7,
            splint_image_8: action.data.splintImage8,
            splint_image_9: action.data.splintImage9,
            splint_image_10: action.data.splintImage10,
            splint_label_1: action.data.splintLabel1,
            splint_label_2: action.data.splintLabel2,
            splint_label_3: action.data.splintLabel3,
            splint_label_4: action.data.splintLabel4,
            splint_label_5: action.data.splintLabel5,
            splint_label_6: action.data.splintLabel6,
            splint_label_7: action.data.splintLabel7,
            splint_label_8: action.data.splintLabel8,
            splint_label_9: action.data.splintLabel9,
            splint_label_10: action.data.splintLabel10,
            // labels
            label_1: action.data.label1,
            label_2: action.data.label2,
            label_3: action.data.label3,
            label_4: action.data.label4,
            label_5: action.data.label5,
            istl_1: action.data.stl1,
            istl_2: action.data.stl2,
            istl_3: action.data.stl3,
            istl_4: action.data.stl4,
            istl_5: action.data.stl5,
            istl_6: action.data.stl6,
            istl_7: action.data.stl7,
            istl_8: action.data.stl8,
            istl_9: action.data.stl9,
            istl_10: action.data.stl10,
            istl_11: action.data.stl11,
            istl_12: action.data.stl12,
            istl_13: action.data.stl13,
            istl_14: action.data.stl14,
            istl_15: action.data.stl15,
            istl_16: action.data.stl16,
            istl_17: action.data.stl17,
            istl_18: action.data.stl18,
            istl_19: action.data.stl19,
            istl_20: action.data.stl20,
            // template video
            video: action.data.video,
            // equipment gif
            gif: action.data.gif,
            // equipment 2d gif
            gif2d: action.data.gif2d,
            // workorder
            workorder: action.data.workorder,
            // treatment
            treatment: action.data.treatment,
            // splint gif
            gif_splint: action.data.gif_splint,
            zip: action.data.zip,
            status: action.data.status,
            status_minivints: action.data.status_minivints,
            status_template: action.data.status_template,
            status_equipment: action.data.status_equipment,
            status_equipment_2d: action.data.status_equipment_2d,
            status_braces: action.data.status_braces,
            status_aligners: action.data.status_aligners,
            status_tray: action.data.status_tray,
            status_splint: action.data.status_splint,
            public: action.data.public,
            version: 1
        };

        const ovRes = yield fetch(`${GATEWAY_HOST}/orderversions`, {
            method: 'POST',
            body: JSON.stringify(body2),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const ovWithoutOrder = yield ovRes.json();

        const body = {
            doctor: action.data.doctor,
            technician: action.data.technician,
            manager: action.data.manager,
            patient: action.data.patient,
            bill: action.data.bill,
            bill_date: action.data.bill_date,
            deadline: action.data.deadline,
            search: `${action.data.doctor_last_name}&${action.data.patient}&${action.data.bill}`,
            status: action.data.status,
            organisation: action.data.organisation,
            status_minivints: action.data.status_minivints,
            status_template: action.data.status_template,
            status_equipment: action.data.status_equipment,
            status_equipment_2d: action.data.status_equipment_2d,
            status_braces: action.data.status_braces,
            status_aligners: action.data.status_aligners,
            status_tray: action.data.status_tray,
            status_splint: action.data.status_splint,
            latest: action.data.design_date,
            version: 1,
            current: ovWithoutOrder.id
        };
        const res = yield fetch(
            `${GATEWAY_HOST}/orders${isEmpty(orderId) ? '' : `/${orderId}`}`,
            {
                method: isEmpty(orderId) ? 'POST' : 'PUT',
                body: JSON.stringify(body),
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        const data = yield res.json();

        yield fetch(`${GATEWAY_HOST}/orderversions/${ovWithoutOrder.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                order: data.id
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        yield put(onAllOrdersLoad());
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}
